$color: rgba(black, 0.5);
$focus: rgba(black, 0.7);

::-webkit-input-placeholder {
  color: $color;
}
input:focus::-webkit-input-placeholder {
  color: $focus;
}
input:-moz-placeholder {
  color: $color;
}
input:focus:-moz-placeholder {
  color: $focus;
}
input::-moz-placeholder {
  color: $color;
}
:focus::-moz-placeholder {
  color: $focus;
}
input:-ms-input-placeholder {
  color: $color;
}
input:focus:-ms-input-placeholder {
  color: $focus;
}
textarea:focus::-webkit-input-placeholder {
  color: $focus;
}
input:-ms-input-placeholder {
  color: $color;
}
::-moz-placeholder {
  color: $color;
}
