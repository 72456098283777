@import "../../../sass/colors";

div#Pricing {
  > img#effect_header_1 {
    position: absolute;
    top: 0;
    right: 0;
  }

  @import "./header";
  @import "./career";
  @import "./howItWorks";
  @import "./faq";
}
