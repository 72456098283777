@import "../../../sass/colors";

div#Upgrade {
  overflow-x: hidden;

  img#effect1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  section#upgradeContainer {
    margin-top: 120px;
    margin-bottom: 120px;
    position: relative;

    img#effect2 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    div.content {
      > div {
        display: flex;
        justify-content: space-between;

        > div.left {
          margin-right: 35px;

          > form {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            padding: 15px 30px;
            min-width: 410px;
            width: 100%;

            h3 {
              text-align: center;
              margin-bottom: 22px;
            }

            div.pricing {
              background: rgba(#3ecf8e, 0.1);
              margin-bottom: 25px;
              padding: 5px 15px;

              p {
                text-align: center;
                span {
                  display: block;
                }
                span.title {
                  font-weight: 600;
                  font-size: 22px;
                }
                span.price {
                  margin-top: 5px;
                  margin-bottom: 5px;
                  font-size: 22px;
                  font-weight: 500;
                }
                span.cancelAnytime {
                  font-size: 16px;
                  font-weight: 500;
                }
              }
            }

            div.containerInputs {
              input {
                max-width: 100%;
                width: 100%;
              }

              div#cardInfo {
                background: #fff;
                border: 1px solid $details;
                border-radius: 4px;
                padding-left: 10px;
                margin: 0;
                padding: 10.5px;
              }

              div.cuponCode {
                font-family: "Source Sans Pro", sans-serif;
                background: #fff;
                border: 1px solid $details;
                border-radius: 4px;
                padding-left: 10px;
                margin: 0;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                button {
                  background: $blue;
                  color: #fff;
                  text-transform: uppercase;
                  box-shadow: 0 4px 15px 0 rgba(70, 99, 246, 0.45);
                  border-radius: 42px;
                  font-weight: 700;
                  font-family: "Source Sans Pro", sans-serif;
                  padding: 5px 12px;
                  outline: none;
                  font-size: 12px;
                  flex-shrink: 0;
                  cursor: pointer;
                  margin: 7px;
                  border: none;
                }

                input {
                  background: transparent;
                  outline: none;
                  border: none;
                  height: inherit;
                  padding: 0;
                  max-width: 100%;
                  width: 100%;
                  height: 40px;
                }
              }

              div.terms {
                margin-bottom: 25px;

                span.checkboxLabel {
                  font-size: 12px;
                  line-height: 15px;
                }
              }
            }

            > button {
              width: 100%;
            }
          }
        }

        > div.right {
          margin-left: 35px;

          h2 {
            max-width: 620px;
            margin-bottom: 30px;
          }
          ul {
            list-style: none;
            padding-left: 20px;

            li {
              font-size: 20px;
              letter-spacing: 0;
              line-height: 30px;
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 35px;
              }
              &:before {
                content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: rgba($black, 0.9); /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
              }
            }
          }
          > div {
            p {
              margin-bottom: 20px;
            }
            figure {
              display: flex;
              align-items: center;
              img {
                margin-left: 45px;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  @import "../../../sass/colors";

  div#Upgrade {
    img#effect1 {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }

    section#upgradeContainer {
      margin-top: 120px;
      margin-bottom: 120px;
      position: relative;

      img#effect2 {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }

      div.content {
        > div {
          display: block;

          > div.left {
            max-width: 600px;
            margin: 0 auto 80px;
          }

          div.right {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  @import "../../../sass/colors";

  div#Upgrade {
    section#upgradeContainer {
      margin-top: 60px;
      margin-bottom: 80px;

      div.content {
        > div {
          div.left {
            form {
              min-width: auto;
              max-width: 100%;
              width: 100%;
              padding: 15px;
            }
          }
          div.right {
            margin-left: 0;

            > div {
              p {
                margin-bottom: 20px;
              }
              figure {
                display: block;

                img {
                  margin: 0;
                  display: block;
                  margin-bottom: 20px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
