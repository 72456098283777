div.Review {
  display: flex;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  span.photo {
    width: 50px;
    height: 50px;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;

    display: block;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-right: 10px;
  }
  > div {
    p.name {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 2px;
    }
    p.feedback {
    }
  }
}
