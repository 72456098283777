@import "../../../sass/colors";

div.Timeline {
  > p.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 15px;
  }
  div.container {
    > div.admin {
      cursor: pointer;
      &:hover {
        background: rgba($detailsLight2, 0.5);
      }
    }
    > div {
      padding-bottom: 30px;
      padding-left: 15px;
      border-left: 1px solid $details;
      position: relative;
      max-width: 230px;

      span.delete {
        position: absolute;
        top: 0;
        right: 5px;
      }

      &:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: $details;
        position: absolute;
        top: 0;
        left: -4px;
        border-radius: 50%;
      }

      &:last-child {
        padding-bottom: 0;
        border: none;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        margin: 0;
        padding: 0;
        span {
          margin: 0;
          font-weight: 500;
        }
      }
      span {
        display: block;
        font-size: 16px;
        margin-top: 7px;
      }
    }
  }
}
