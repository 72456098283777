@mixin UserLoggedDesktop {
  li.liUserLogged {
    position: relative;
    margin-left: 48px !important;

    div.userLogged {
      display: flex;
      justify-content: center;
      cursor: pointer;

      > span.photo {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 5px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      > div {
        p.name {
          font-size: 14px;
          margin-bottom: 0;
        }
        span.userType {
          margin-top: 0;
          background: $blue;
          color: #fff;
          padding: 2px 12px;
          font-size: 12px;
          border-radius: 8px;
          font-weight: 600;
        }
      }
      > svg {
        margin-top: 5px;
        margin-left: 8px;
      }
    }
    > ul.active {
      display: block;
    }
    > ul {
      position: absolute;
      //bottom: -320px;
      right: 0;
      background: #fff;
      display: none;
      box-shadow: 0 15px 24px 0 rgba(75, 68, 128, 0.13);
      border-radius: 4px;
      z-index: 999999;

      li {
        margin: 0;

        &:hover {
          background: $detailsLight;
        }

        a {
          display: flex;
          align-items: center;
          white-space: nowrap;
          padding: 12px 25px;
          border-bottom: 1px solid $detailsLight;
          color: $black;

          svg {
            margin-right: 22px;
          }
        }
      }
    }
  }
}
