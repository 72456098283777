@import "../../../sass/_colors";

div#MyListings {
  background: $bkg;

  section {
    margin-top: 100px;
    margin-bottom: 100px;

    div.myListingsContainer {
      margin-bottom: 65px;
      flex-wrap: wrap;
      justify-content: flex-start;

      a.MyListingCard:not(.editListing) {
        margin: 11px;
      }
    }
    div.myFavouritesContainer {
      margin-bottom: 65px;
      flex-wrap: wrap;
      justify-content: flex-start;

      div.MyFavorite {
        margin: 11px;
      }
    }
  }
}
@media (max-width: 600px) {
  div#MyListings {
    section {
      margin-top: 50px;
    }
  }
}
