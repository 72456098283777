@import "../../../sass/_colors";

footer.footer {
  padding-bottom: 50px;
  padding-top: 50px;
  background: white;

  div.content {
    > div.flex {
      justify-content: space-between;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 10px;

          &:first-child {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 15px;
          }
          a {
            font-size: 14px;
            outline: none;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.7);
            transition: 0.35s;

            &:hover {
              color: $blue;
            }
          }
        }
      }

      > div {
        &:first-child {
          max-width: 345px;

          > img {
            margin-bottom: 15px;
          }
          p.headline {
            font-size: 18px;
            font-weight: 600;
          }
          p.about {
            font-size: 14px;
            line-height: 24px;
          }
          p.copyRight {
            font-size: 12px;
          }
        }
        &:last-child {
          max-width: 510px;
          width: 100%;
          justify-content: space-between;

          ul.getInTouch {
            li.social {
              margin-top: 15px;

              a {
                display: inline-block;
                padding: 10px;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 12px;
                border: 1px solid #efedfa;

                &:hover {
                  background: #fff;
                  box-shadow: 0 2px 5px 0 rgba(51, 45, 97, 0.12);
                }

                &:last-child {
                  margin: 0;
                }
              }
            }
          }

          > div {
            ul:last-child {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  footer.footer {
    padding-bottom: 50px;
    padding-top: 50px;

    div.content {
      > div.flex {
        flex-direction: column;

        > div {
          flex-direction: column;
          text-align: center;
          margin: 0 auto;

          &:first-child {
            margin-top: 20px;
            order: 2;

            > img {
              margin-bottom: 15px;
            }
            p.headline {
              font-size: 18px;
              font-weight: 600;
            }
            p.about {
              font-size: 14px;
              line-height: 24px;
            }
            p.copyRight {
              font-size: 12px;
            }
          }
          &:last-child {
            order: 1;
            max-width: 510px;
            width: 100%;
            justify-content: space-between;

            ul.mentory {
              margin-bottom: 20px;
            }
            div.mentors_mentees {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
