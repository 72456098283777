@import "../../../sass/_colors";
.ModalEndRateSession {
  span.photo {
    display: block;
    width: 120px;
    height: 120px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  h4 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0 !important;
  }
  p.mentorName {
    text-align: center;
    margin-bottom: 40px;
  }
  form {
    div.rate {
      margin-bottom: 20px;
      label {
        font-weight: 600;
        padding-bottom: 12px;
        display: block;
      }
      .rc-rate {
        display: block;
      }
    }
    > div.actions {
      margin: 0;
      padding: 0;
      align-items: center;
      margin-top: 20px;
      p {
        text-transform: uppercase;
        margin-left: 30px;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0;
        cursor: pointer;
        transition: 0.35s;
        opacity: 0.8;
        &:hover {
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
  }
}

div#Chat {
  background: $bkg;
  min-height: 100vh;
  height: 100%;
  section {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 50px;
    margin-right: 50px;
    div.wrap {
      display: flex;
      justify-content: center;
      div.userPanel {
        background: #fff;
        box-shadow: 0 6px 14px 0 rgba(75, 68, 128, 0.13);
        border-radius: 6px 0 0 6px;
        max-width: 400px;
        height: 600px;
        width: 100%;
        padding: 30px;
        overflow: hidden;
        overflow-y: auto;
        div.main {
          > div {
            display: flex;
            justify-content: space-between;
            div#filter {
              position: relative;
              cursor: pointer;
              ul {
                li.active {
                  background: $blue;
                  color: #fff;
                }
              }
            }
          }
          form {
            margin-top: 15px;
          }
        }
        article {
          span.status {
            display: inline-block;
            width: auto;
            border-radius: 11px;
            font-weight: 700;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 0.5px;
            text-align: center;
            line-height: 16px;
            padding: 4px 10px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
          > div.contact.selected {
            background: $detailsLight;
            border-radius: 4px;
          }
          > div.contact {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 5px;

            cursor: pointer;

            &:last-child {
              margin-bottom: 0;
            }
            > div {
              display: flex;
              align-items: center;
              span.photo {
                width: 50px;
                height: 50px;
                display: block;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                margin-right: 12px;
                &:after {
                  content: "";
                  display: block;
                  width: 9px;
                  height: 9px;
                  background: #edeaea;
                  position: absolute;
                  top: 0;
                  right: 0;
                  border-radius: 50%;
                  border: 2px solid #fff;
                }
              }
              > div {
                p {
                  margin: 0;
                  &:first-child {
                    font-weight: 700;
                    font-size: 14px;
                  }
                  &:last-child {
                    font-size: 13px;
                    opacity: 0.8;
                    margin-top: -5px;
                  }
                }
              }
            }
            span.notification {
              display: block;
              background: #ef5555;
              border-radius: 50%;
              font-size: 12px;
              font-weight: 600;
              position: relative;
              width: 22px;
              height: 22px;
              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                color: #fff;
                font-style: normal;
              }
            }
          }
        }
      }
      div.chat {
        width: 100%;
        max-width: 700px;
        > div.nothingSelected {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 18px;
            opacity: 0.8;
            span {
              font-size: 20px;
            }
          }
        }
        > div {
          width: inherit;
          height: 600px;
          background: #ffffff;
          box-shadow: 0 6px 14px 0 rgba(75, 68, 128, 0.13);
          border-radius: 0 6px 6px 0;
          display: flex;
          flex-direction: column;
          main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px 10px 40px;
            border-bottom: 1px solid $details;
            > div.first {
              display: flex;
              align-items: center;
              span.back {
                padding-right: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                display: none;
              }
              p {
                font-weight: 700;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                span {
                  display: block;
                  font-weight: 600;
                  font-size: 13px;
                  opacity: 0.8;
                }
              }
            }
            > div.last {
              position: relative;
              cursor: pointer;
              display: flex;
              align-items: center;
              span.start_call {
                display: block;
                margin-right: 20px;
              }
              span.finish_call {
                display: block;
                margin-right: 20px;
                svg {
                  color: #ef5555;
                }
              }
              span.moreOptions {
                padding: 10px;
                padding-right: 0;
              }
            }
          }
          div#messages {
            background: #fff;
            height: 100%;
            padding: 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
            overflow-y: auto;
            > p.msg {
              font-size: 15px;
              flex-shrink: 0;
              display: inline-block;
              border-radius: 16px;
              padding: 6px 17px;
              margin-bottom: 30px;
              max-width: 50%;
              position: relative;
              span.spanDate {
                position: absolute;
                bottom: -25px;
                right: 0;
                font-size: 12px;
                white-space: nowrap;
              }
            }
            span.spanMessage.file {
              svg {
                display: inline-block;
                margin-right: 5px;
              }
            }
            > p.msg.i {
              background: $blue;
              align-self: flex-end;
              span.spanMessage {
                color: #fff;
                a {
                  color: #fff;
                }
              }
            }
            > p.msg.contact {
              background: #ecebf0;
              align-self: flex-start;
            }
            > p.noMessages {
              align-self: center;
              font-size: 20px;
              opacity: 0.6;
            }
          }
          form {
            font-family: "Source Sans Pro", sans-serif;
            div.input {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 50px;
              max-width: 90%;
              margin: 15px auto 30px;
              background: #ffffff;
              border: 1px solid $details;
              box-shadow: 0 16px 19px 0 rgba(75, 68, 128, 0.13);
              border-radius: 28px;
              > div {
                display: flex;
                align-items: center;
                height: 100%;
                &:first-child {
                  width: 70%;
                  margin-left: 10px;
                  div#emojis {
                    margin-right: 12px;
                    position: relative;
                    cursor: pointer;
                    ul.active {
                      display: flex;
                    }
                    ul {
                      list-style-type: none;
                      position: absolute;
                      bottom: 20px;
                      left: 0;
                      background: #fff;
                      box-shadow: 0 16px 19px 0 rgba(75, 68, 128, 0.13);
                      border: 1px solid $details;
                      min-width: 250px;
                      width: 100%;
                      height: 150px;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: flex-start;
                      border-radius: 4px;
                      align-content: flex-start;
                      overflow: hidden;
                      overflow-y: auto;
                      display: none;
                      li {
                        font-size: 21px;
                        margin: 5px;
                        cursor: pointer;
                      }
                    }
                  }
                  input {
                    text-decoration: none;
                    outline: none;
                    border: none;
                    height: 100%;
                    width: 100%;
                  }
                }
                &:last-child {
                  width: 100px;
                  justify-content: flex-end;
                  margin-right: 10px;
                  div#file {
                    cursor: pointer;
                    input {
                      display: none;
                    }
                  }
                  button {
                    background: $blue;
                    box-shadow: 0 9px 9px 0 rgba(70, 99, 246, 0.32);
                    border-radius: 50%;
                    width: 42px;
                    height: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    outline: none;
                    border: none;
                    color: #fff;
                    padding-top: 2px;
                    padding-right: 2px;
                    margin-left: 12px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  div#Chat {
    section {
      margin: 0;
      padding-top: 0;
      div.wrap {
        position: relative;
        div.userPanel {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          max-width: 100%;
          height: 100vh;
        }
        div.chat {
          > div {
            position: fixed;
            top: 0;
            left: -100%;
            max-width: 100%;
            width: 100%;
            height: 100vh;
            max-height: 100vh;
            min-height: 100vh;
            overflow: hidden;
            z-index: 99;
            main {
              > div.first {
                display: flex;
                align-items: center;
                span.back {
                  padding-right: 10px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  display: block;
                }
              }
            }
            form {
              div.input {
                margin-bottom: 85px;
                margin-left: 0;
                margin-right: 0;
                max-width: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.rc-rate {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;
}

.rc-rate-disabled .rc-rate-star:before,
.rc-rate-disabled .rc-rate-star-content:before {
  cursor: default;
}

.rc-rate-disabled .rc-rate-star:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.rc-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  color: #e9e9e9;
  cursor: pointer;
  line-height: 1.5;
}

.rc-rate-star-first,
.rc-rate-star-second {
  transition: all 0.3s;
}

.rc-rate-star-focused,
.rc-rate-star:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.rc-rate-star-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-half .rc-rate-star-second {
  opacity: 1;
}

.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-full .rc-rate-star-second {
  color: $blue;
}

.rc-rate-star-half:hover .rc-rate-star-first,
.rc-rate-star-full:hover .rc-rate-star-second {
  color: $blue;
}

@font-face {
  font-family: "anticon";
  src: url("//at.alicdn.com/t/font_r5u29ls31bgldi.eot");
  /* IE9*/
  src:
    url("//at.alicdn.com/t/font_r5u29ls31bgldi.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/font_r5u29ls31bgldi.woff")
      format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/font_r5u29ls31bgldi.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/font_r5u29ls31bgldi.svg#iconfont") format("svg");
  /* iOS 4.1- */
}

.anticon {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.anticon:before {
  display: block;
  font-family: "anticon" !important;
}

.anticon-star:before {
  content: "\e660";
}

//Rate
.rc-rate {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;
}

.rc-rate-disabled .rc-rate-star:before,
.rc-rate-disabled .rc-rate-star-content:before {
  cursor: default;
}

.rc-rate-disabled .rc-rate-star:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.rc-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  color: #e9e9e9;
  cursor: pointer;
  line-height: 1.5;
}

.rc-rate-star-first,
.rc-rate-star-second {
  transition: all 0.3s;
}

.rc-rate-star-focused,
.rc-rate-star:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.rc-rate-star-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-half .rc-rate-star-second {
  opacity: 1;
}

.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-full .rc-rate-star-second {
  color: $blue;
}

.rc-rate-star-half:hover .rc-rate-star-first,
.rc-rate-star-full:hover .rc-rate-star-second {
  color: $blue;
}

@font-face {
  font-family: "anticon";
  src: url("//at.alicdn.com/t/font_r5u29ls31bgldi.eot");
  /* IE9*/
  src:
    url("//at.alicdn.com/t/font_r5u29ls31bgldi.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/font_r5u29ls31bgldi.woff")
      format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/font_r5u29ls31bgldi.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/font_r5u29ls31bgldi.svg#iconfont") format("svg");
  /* iOS 4.1- */
}

.anticon {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.anticon:before {
  display: block;
  font-family: "anticon" !important;
}

.anticon-star:before {
  content: "\e660";
}

#VideoConference {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999999999999;
  div#actions {
    background: #fff;
    padding: 5px;
    border: 1px solid $details;
    border-bottom: none;
    display: flex;
    justify-content: flex-end;
    button#finishCall {
      margin-left: 10px;
      background: #ef5555;
    }
    button {
      display: inline-block;
      background: $blue;
      width: 24px;
      height: 24px;
      border: none;
      outline: none;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      svg {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: white;
      }
    }
  }
  div#iframe {
    width: 100%;
    height: 100%;
    iframe {
      border: 1px solid $details;
    }
  }
}

#VideoConference.opened {
  display: block;
}

//Stats
#VideoConference.desktopFull {
  iframe {
    width: 50vw;
    height: 60vh;
  }
}

#VideoConference.desktopSmall {
  transform: none;
  top: 0;
  left: 0;
  iframe {
    width: 40vw;
    height: 55vh;
  }
}

#VideoConference.mobileFull {
  transform: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 95vh;
  iframe {
    width: 100%;
    height: inherit;
  }
}

#VideoConference.mobileSmall {
  transform: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  iframe {
    width: 100%;
    height: inherit;
  }
}
