@import "../../../../sass/colors";

div.rangeslider {
  background: $details;
  box-shadow: none;

  div.rangeslider__handle {
    background: #fff;
    border: 1px solid $details;
    box-shadow: 0 8px 14px 0 rgba(51, 45, 97, 0.1);
    outline: none;

    &:after {
      display: none;
    }
  }

  div.rangeslider__handle-tooltip {
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(75, 68, 128, 0.11);
    border-radius: 6px;
    border: 1px solid $details;
    width: 70px;

    &:after {
      display: none;
    }
  }
  div.rangeslider__fill {
    box-shadow: none;
    background: $details;
  }
  li.rangeslider__label-item {
    text-align: center;
  }
}
