@import "../../../sass/colors";

div.Separator {
  > h3 {
    font-family: "Source Sans Pro", sans-serif;
    border-bottom: 1px solid $details;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }
}
