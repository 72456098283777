.Popup {
  div.actions {
    span {
      display: none;
    }
    button {
      width: 100%;
    }
  }
}

.Popup.mobile {
  display: none;
  padding-bottom: 50px;

  div.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 30px;

    span {
      display: block;
      cursor: pointer;
    }
    button {
      width: 80%;
    }
  }

  div.contentPopupComponent {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.Popup.mobile.opened {
  display: block;
}
