@import "../../../sass/colors";

div#ResetPassword {
  > section {
    height: 100vh;
    min-height: 600px;

    div.content {
      text-align: center;
      display: flex;

      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-width: 650px;
      margin: 0 auto;

      a.back {
        position: absolute;
        top: 25px;
        right: 25px;
      }

      > div {
        width: 100%;

        p.subtitle {
          margin-top: 15px;
          margin-bottom: 30px;
        }

        form.simple {
          button {
            margin-top: 0;
            width: 100%;
          }
          a {
            margin-top: 40px;
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  div#ResetPassword {
    > section {
      div.content {
        a.back {
          display: none;
        }
      }
    }
  }
}
