@import "./_colors";

span.form.error {
  font-size: 14px;
  color: #ff1919;
  display: inline-block;
  margin-top: 5px;
}

form.stylish {
  > div {
    background: #ffffff;
    box-shadow: 0 12px 34px 0 rgba(75, 68, 128, 0.13);
    border-radius: 39px;
    display: flex;
    justify-content: space-between;
    padding: 7px;

    p {
      width: 60%;
      display: flex;
      align-items: center;
      margin-left: 25px;

      input {
        width: 100%;
        padding-top: 22px;
        padding-bottom: 22px;
        border: none;
        outline: none;
        font-size: 16px;
        padding-left: 10px;
      }
    }
    button {
    }
  }
}

@media (max-width: 500px) {
  form.stylish {
    > div {
      background: transparent;
      box-shadow: none;
      border-radius: 0;
      display: block;
      padding: 0;

      p {
        width: 100%;
        background: #ffffff;
        box-shadow: 0 12px 34px 0 rgba(75, 68, 128, 0.13);
        border-radius: 39px;
        margin: 0;
        border-radius: 42px;
        height: 60px;
        padding-left: 20px;
        padding-right: 20px;

        input {
          background: transparent;
        }
      }
      button {
        max-width: 100% !important;
        width: 100%;
        border-radius: 42px;
        height: 60px;
        margin: 15px auto 0;
      }
    }
  }
}

//Simple form
form.simple {
  //Input
  div.Input.Location {
    position: relative;
    ul {
      list-style-type: none;
      background: #fff;
      box-shadow: 1px 2px 3px rgba(black, 0.2);
      position: absolute;
      left: 0;
      width: 100%;
      display: block;
      max-height: 200px;
      overflow-y: auto;
      z-index: 9;

      > li.suggestion-item--active {
        background: $detailsLight;
      }
      > li {
        cursor: pointer;
        padding: 10px 15px;

        &:hover {
          background: $detailsLight;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  div.Input {
    margin-bottom: 20px;
    text-align: left;

    > label {
      display: block;
      padding-bottom: 12px;
      font-weight: 600;
    }

    > p.textarea {
      height: 150px;
    }
    > p {
      background: #fff;
      border: 1px solid $details;
      border-radius: 4px;
      padding-left: 10px;
      margin: 0;
      padding: 0;
      height: 48px;
      justify-content: space-between;
      align-items: center;

      input {
        background: transparent;
        outline: none;
        border: none;
        height: inherit;
        padding-left: 15px;
        max-width: 85%;
        width: 100%;
      }
      textarea {
        background: transparent;
        outline: none;
        border: none;
        height: inherit;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 100%;
        width: 100%;
      }

      svg {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    > small {
      display: block;
      margin-top: 5px;
      font-size: 13px;
      opacity: 0.7;
    }
  }

  //InputTag
  div.InputTag {
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;

    > label {
      display: block;
      padding-bottom: 12px;
      font-weight: 600;
    }

    > ul.autocomplete {
      list-style-type: none;
      background: #fff;
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 99999999;
      overflow-y: auto;
      box-shadow: 0 16px 19px 0 rgba(75, 68, 128, 0.13);
      border-radius: 4px;
      border: 1px solid $detailsLight2;
      max-height: (5 * 38px) + 5px;

      > li.notFound {
        cursor: auto;
        &:hover {
          background: #fff;
        }
      }
      > li.active {
        background: $detailsLight;
      }
      > li {
        cursor: pointer;
        padding: 10px 15px;

        &:hover {
          background: $detailsLight;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > p {
      background: #fff;
      border: 1px solid $details;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      min-height: 48px;
      justify-content: space-between;
      align-items: center;
      position: relative;

      > span {
        flex-wrap: wrap;
        align-items: center;
        height: inherit;

        span {
          background: $blue;
          outline: none;
          padding-left: 5px;
          padding-right: 5px;
          border: none;
          color: #fff;
          margin: 5px;
          border-radius: 2px;
        }
        input {
          background: transparent;
          outline: none;
          border: none;
          height: inherit;
          max-width: 100px;
          width: 100%;
          padding-left: 5px;
        }
      }

      svg {
        margin-left: 10px;
        margin-right: 10px;
        min-width: 16px;
        min-height: 16px;
      }
    }
  }

  //RadioButtom
  div.RadioButtom {
    display: block !important;
    margin-bottom: 20px;

    > p {
      display: block;
      padding-bottom: 12px;
      font-weight: 600;
    }

    > div.container.h {
      display: flex;

      > p {
        margin-left: 30px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    > div.container.v {
      > p {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  //WysiwygEditor
  div.WysiwygEditor {
    margin-bottom: 20px;

    > p {
      display: block;
      padding-bottom: 12px;
      font-weight: 600;
    }

    > div {
      background: #fff;
      border: 1px solid $details;
      border-radius: 4px;
      min-height: 150px;
      height: 100%;
      padding: 15px;
      padding-bottom: 30px;
      position: relative;

      div.editorContainer {
        font-family: "Source Sans Pro", sans-serif;
        line-height: 24px;
        min-height: 240px;
        font-size: 15px;
      }
    }
  }

  //Dropdown
  div.Dropdown {
    margin-bottom: 20px;
    position: relative;
    z-index: 9;

    > label {
      display: block;
      padding-bottom: 12px;
      font-weight: 600;
    }
    > p {
      background: #fff;
      border: 1px solid $details;
      border-radius: 4px;
      padding-left: 10px;
      margin: 0;
      padding: 0;
      height: 48px;
      justify-content: space-between;
      align-items: center;

      input {
        background: transparent;
        outline: none;
        border: none;
        height: inherit;
        padding-left: 15px;
        max-width: 85%;
        width: 100%;
        cursor: pointer !important;
      }
      svg {
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
        min-width: 16px;
        min-height: 16px;
      }
    }
    > small {
      display: block;
      margin-top: 5px;
      font-size: 13px;
      opacity: 0.7;
    }

    > ul.opened {
      display: block;
    }

    > ul {
      list-style-type: none;
      background: #fff;
      box-shadow: 1px 2px 3px rgba(black, 0.2);
      position: absolute;
      left: 0;
      width: 100%;
      display: none;
      max-height: 200px;
      overflow-y: auto;

      > li.active {
        background: $detailsLight;
      }
      > li {
        cursor: pointer;
        padding: 10px 15px;

        &:hover {
          background: $detailsLight;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

form.simple.smart {
  $height: 40px;
  div.Input {
    > label {
      padding-bottom: 8px;
    }
    > p {
      height: $height;
    }
  }
  div.InputTag {
    > label {
      padding-bottom: 8px;
    }
    > p {
      min-height: $height;
    }
  }
  div.Dropdown {
    > label {
      padding-bottom: 8px;
    }
    > p {
      height: $height;
    }
  }
}
