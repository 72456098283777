@import "../../../../sass/colors";

div.ActiveSessionMentee {
  display: flex;
  justify-content: flex-start;
  background: #fff;
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 22px 32px;
  position: relative;
  margin: 11px;

  outline: none;

  div.settings {
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
    padding: 2px;
  }

  span.photo {
    width: 120px;
    height: 120px;
    display: block;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 30px;
  }
  > div {
    h4 {
      line-height: 24px;
    }
    p.description {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
    }
    p.received {
      font-size: 13px;
      letter-spacing: 0;
    }
    p.youWillMake {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
    }
  }
}

@media (max-width: 500px) {
  div.ActiveSessionMentee {
    flex-direction: column;
    justify-content: center;

    span.photo {
      margin: 0 auto 15px;
    }
  }
}
