@import "../../../../sass/colors";

.InputDateEditRequest {
  div.react-datepicker-wrapper,
  div.react-datepicker__input-container {
    width: 100%;
  }
}

div.Input.InputDate:not(.InputDateEditRequest) {
  cursor: pointer;

  input {
    cursor: pointer;
  }

  div.react-datepicker__month--selected {
    background-color: transparent !important;
    color: black;
  }

  div.react-datepicker-wrapper,
  div.react-datepicker__input-container {
    max-width: 100%;
    width: 100%;
  }

  //Time
  div.react-datepicker__time-container {
    width: 90px;

    div.react-datepicker__time {
      li {
        line-height: unset !important;
        height: auto !important;
        padding: 6px 0 !important;
      }
    }

    .react-datepicker__time-box {
      width: auto !important;
    }
    li.react-datepicker__time-list-item--selected {
      background: $blue !important;
    }
  }

  > small {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    opacity: 0.7;
  }

  //Calendar
  div.react-datepicker {
    background: #ffffff;
    border: 1px solid $details;
    border-radius: 4px;
  }
  div.react-datepicker__triangle {
    display: none;
  }
  div.react-datepicker__header {
    background: #fff;
    border: none;
  }
  div.react-datepicker__day-names {
    text-transform: uppercase;
    div.react-datepicker__day-name:first-child {
      opacity: 0.4;
    }
  }

  div.react-datepicker__day--disabled {
    opacity: 0.4;
  }

  div.react-datepicker__day.react-datepicker__day--selected {
    background: $blue !important;
    color: #fff !important;
    font-weight: 700;
  }
  div.react-datepicker__day--keyboard-selected,
  div.react-datepicker__month-text--keyboard-selected {
    color: #fff;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 20px;
  }
  div.react-datepicker__day {
    border-radius: 2px;
  }

  * {
    font-family: "Source Sans Pro", sans-serif;
    //color: $black;
  }
}
