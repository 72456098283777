section#whatCanIarchieve {
  margin-bottom: 150px;

  div.content {
    > h2,
    > p {
      text-align: center;
    }
    > p {
      max-width: 600px;
      margin: 10px auto 50px;
    }

    article {
      div.desktop {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
      }

      .slick-slider.slick-initialized {
        display: none;
      }

      div.testimonialsCard.space {
        margin-right: 25px;
      }

      div.testimonialsCard {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 25px 15px;
        max-width: 450px;
        min-width: 350px;
        width: 100%;
        margin-top: 25px;

        h3 {
          font-weight: 600;
          margin-bottom: 15px;
        }

        div.action {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p.desc {
            margin-right: 20px;
            display: flex;

            > span {
              display: flex;
              align-items: center;

              &:first-child {
                svg {
                  margin-right: 5px;
                }
              }

              &:last-child {
                margin-left: 20px;
              }
            }
          }
          button {
            padding: 12px 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  section#whatCanIarchieve {
    margin-bottom: 150px;

    div.content {
      article {
        div.testimonialsCard {
          max-width: 700px;
          margin: 0 auto 25px !important;

          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  section#whatCanIarchieve {
    div.content {
      article {
        .slick-slider.slick-initialized {
          display: block;
        }
        div.testimonialsCard {
          div.action {
            display: block;
            > p.desc {
              margin-bottom: 15px;
            }
          }
        }

        div.desktop {
          display: none;
        }
      }
    }
  }
}
