@import "../../../sass/colors";

div.Modal.active {
  display: flex;
}

div.Modal {
  background: rgba(black, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999999;
  display: none;
  align-items: center;
  justify-content: center;

  > div.container.noHead {
    > div.flex {
      border: none;
      padding-bottom: 0;
    }
    div.wrap {
      padding-top: 0;
    }
  }
  > div.container {
    background: #fff;
    max-width: 700px;
    width: 100%;

    height: auto;
    min-height: 300px;
    max-height: 670px;

    box-shadow: 0 4px 8px 0 rgba(75, 68, 128, 0.11);
    border-radius: 4px;
    overflow-y: auto;

    > div.flex {
      max-width: 100%;
      width: 100%;
      padding: 20px;
      padding-left: 30px;
      border-bottom: 1px solid $detailsLight;
      justify-content: space-between;
      align-items: center;

      p.title {
        font-weight: 700;
        font-size: 20px;
      }

      svg {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.35s;

        &:hover {
          opacity: 1;
        }
      }
    }

    > div.wrap {
      padding: 30px;
      padding-bottom: 50px;

      h4 {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 750px) {
  div.Modal {
    > div.container {
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}
