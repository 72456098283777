@mixin UserLoggedMobile {
  div.logged {
    main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid $details;
      margin-bottom: 0;

      > div {
        padding: 5px;
      }
    }

    div.userLogged {
      display: flex;
      cursor: pointer;
      padding: 15px;
      background: $bkg;
      margin-bottom: 15px;

      > span.photo {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin-right: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      > div {
        p.name {
          font-size: 16px;
          margin-bottom: 0;
        }
        span.userType {
          margin-top: 5px;
          background: $blue;
          color: #fff;
          padding: 2px 12px;
          font-size: 14px;
          border-radius: 8px;
          font-weight: 600;
        }
      }
    }

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      li.divide {
        border-bottom: 1px solid $details;
        padding-bottom: 30px;
      }

      li.notification {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 10px;
          left: 90px;
          width: 6px;
          height: 6px;
          display: block;
          background: #ff0000;
          border-radius: 50%;
        }
      }

      li {
        margin-bottom: 20px;

        a {
          outline: none;
          text-decoration: none;
          color: $black;
          font-weight: 600;
          font-size: 20px;
          display: flex;
          align-items: center;
          padding: 15px;

          svg {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
