div#TheIdea {
  img#detail1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  section {
    margin-top: 100px;
    position: relative;
    z-index: 9;

    img#detail2 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    h1 {
      text-align: center;
      margin-bottom: 100px;
    }

    div.content {
      article {
        display: flex;
        justify-content: space-between;
        max-width: 880px;
        margin: 0 auto;

        > div:first-child {
          text-align: center;

          span.photo {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            display: block;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 auto 15px;
          }
          ul {
            list-style-type: none;

            li.social {
              margin-top: 15px;

              a {
                display: inline-block;
                padding: 10px;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 12px;
                border: 1px solid #efedfa;

                &:hover {
                  background: #fff;
                  box-shadow: 0 2px 5px 0 rgba(51, 45, 97, 0.12);
                }

                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }

        > div:last-child {
          max-width: 620px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  div#TheIdea {
    section {
      h1 {
        margin-bottom: 40px;
      }

      div.content {
        article {
          display: block;

          > div:last-child {
            max-width: 500px;
            margin: 50px auto;
          }
        }
      }
    }
  }
}
