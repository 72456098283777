article#about-you {
  margin-left: 25px;

  div.MentoryBox {
    margin-bottom: 32px;

    &:last-child {
      margin-top: 0;
    }

    > div.container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div.dropdownCurrency {
        z-index: 9;
        small {
          span {
            color: #ff0000;
          }
        }
      }
      div.dropdownGender {
        z-index: 8;
      }

      div.Input,
      div.Dropdown {
        max-width: 48%;
        min-width: 200px;
        width: 100%;
      }
      div.Input.languages,
      div.Input.profileBio,
      div.InputTag {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  div#experience {
    > div.container {
      justify-content: flex-start;

      > div {
        &:first-child {
          margin-right: 35px;
        }
        &:last-child {
          margin-left: 35px;
        }

        button {
          min-width: 200px;
          margin-top: 30px;
        }
      }
    }
  }
  div#skills {
    div.container {
      display: block;

      a.tag {
        border-radius: 4px;
        padding: 4px 14px;
        background: $detailsLight;
        outline: none;
        white-space: nowrap;
        border: none;
        cursor: pointer;
        margin-top: 12px;
        display: inline-block;

        span {
          display: flex;
          align-items: center;
          svg {
            display: block;
            margin-right: 5px;
          }
        }
      }

      button {
        margin-top: 20px;
      }
    }
  }

  div.actions {
    align-items: center;
    margin-top: 50px;

    button {
      max-width: 220px;
      width: 100%;
    }
    p {
      margin-left: 30px;
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
      opacity: 0.8;
      transition: 0.35s;

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }

  div.Modal.addSkillsModal {
    div.container {
      min-height: 380px;

      ul.autocomplete {
        max-height: 120px;
      }
    }
    a.tag {
      border-radius: 4px;
      padding: 4px 14px;
      background: $detailsLight;
      outline: none;
      white-space: nowrap;
      border: none;
      margin-top: 12px;
      pointer-events: none;
      display: inline-block;
    }
  }
}

@media (max-width: 850px) {
  article#about-you {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 600px) {
  article#about-you {
    div.MentoryBox {
      > div.container {
        display: block;
        > div.Input,
        div.Dropdown {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    div#experience {
      > div.container {
        > div {
          margin: 0 !important;

          &:first-child {
            margin-bottom: 50px !important;
          }
        }
      }
    }

    div.actions {
      display: block;
      margin: 50px auto 0;

      button {
        max-width: 100%;
      }
      p {
        margin-left: 0;
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}

div.Modal.addExperienceModal,
div.Modal.addEducationModal {
  z-index: 999;
}
