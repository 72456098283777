@mixin UserLoggedOutMobile {
  div.loggedOut {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid $details;
      margin-bottom: 50px;

      > div {
        padding: 5px;
      }
    }

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      li {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
        a {
          outline: none;
          text-decoration: none;
          color: $black;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }

    div.actions {
      margin-bottom: 45px;
      padding: 15px;

      > a {
        text-align: center;
        display: block;
        outline: none;
        text-decoration: none;
        font-weight: 600;

        button {
          margin-top: 20px;
          max-width: 100%;
          width: 100%;
          padding-bottom: 15px;
          padding-top: 15px;
          font-size: 16px;
        }
      }
    }
  }
}
