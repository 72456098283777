div#Home {
  background-image: url(../../../assets/home/effect.svg);
  background-repeat: no-repeat;
  background-position: right top;

  header {
    width: 100%;
    position: relative;

    > figure {
      position: absolute;
      right: 0;
      z-index: 999;
      height: 100%;
      max-width: 650px;
      width: 100%;
      overflow: hidden;
      z-index: 1;

      span {
        position: absolute;
        display: block;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        box-shadow: 0 30px 34px 0 rgba(51, 45, 97, 0.3);
      }

      span.p1 {
        width: 80px;
        height: 80px;
        top: 0;
        left: 130px;
      }
      span.p2 {
        width: 80px;
        height: 80px;
        top: 120px;
        right: 100px;
      }
      span.p3 {
        width: 80px;
        height: 80px;
        top: 0;
        right: -65px;
      }
      span.p4 {
        width: 65px;
        height: 65px;
        top: 150px;
        left: 25px;
      }
      span.p5 {
        width: 110px;
        height: 110px;
        top: 45%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      span.p6 {
        width: 95px;
        height: 95px;
        top: 60%;
        left: 40px;
        transform: translateY(-50%);
      }
      span.p7 {
        width: 60px;
        height: 60px;
        top: 75%;
        left: 40%;
        transform: translateX(-50%) translateY(-50%);
      }
      span.p8 {
        width: 90px;
        height: 90px;
        bottom: 90px;
        right: 0;
      }
    }

    div.content {
      padding-top: 120px;
      margin-top: 50px;
      padding-bottom: 50px;

      main {
        padding: 40px;
        padding-left: 0;
        max-width: 670px;
        width: 100%;
        position: relative;
        z-index: 2;
        background: transparent;

        > h1 {
          margin-bottom: 20px;
        }

        form {
          margin-top: 40px;
        }

        div.popularCategories {
          align-items: center;
          margin-top: 25px;

          > p {
            margin-right: 8px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  div#Home {
    background: none;

    header {
      background-image: linear-gradient(145deg, #ff6236 21%, #ff1081 100%);
      padding-bottom: 120px;
      padding-top: 120px;

      > figure {
        display: none;
      }

      div.content {
        padding-top: 0;
        margin-top: 0;

        main {
          text-align: center;
          padding: 0;
          margin: 0 auto;

          > h1 {
            color: #fff;
            margin-bottom: 20px;
          }
          > p {
            color: #fff;
          }

          form {
            margin-top: 40px;
          }

          div.popularCategories {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  div#Home {
    header {
      padding-bottom: 90px;
      padding-top: 90px;
    }
  }
}
