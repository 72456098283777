@import "../../../sass/_colors";

div.QuestionFAQ {
  div.title {
    min-height: 59px;
    height: 100%;

    border-top: 1px solid $details;
    padding: 15px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;

    span.icon {
      height: 28px;
    }
  }

  div.answer {
    height: 100%;
    max-height: 0;
    padding: 0;

    overflow: hidden;
    background: #fff;

    transition: 0.2s;

    p {
      margin: 0;
      padding: 25px;
    }
  }
}

div.QuestionFAQ.opened {
  div.answer {
    max-height: 500px;
    transition: 0.5s;
  }
}
