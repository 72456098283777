@import "../../../sass/colors";

div#Login {
  > section {
    height: 100vh;
    min-height: 800px;

    div.content {
      text-align: center;
      display: flex;

      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;

      a.back {
        position: absolute;
        top: 25px;
        right: 25px;
      }

      > div {
        width: 100%;

        a {
          color: $blue;
          text-decoration: none;
          font-weight: 700;
        }

        > a {
          img {
            margin-bottom: 50px;
          }
        }

        form.simple {
          button {
            margin-top: 10px;
            width: 100%;
          }
          a {
            margin-top: 40px;
            display: block;
          }
        }

        div.footer {
          > div {
            margin: 10px auto 10px;
          }

          p {
            &:first-child {
              margin-top: 65px;
            }
            &:last-child {
              margin-bottom: 70px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  div#Login {
    > section {
      div.content {
        a.back {
          display: none;
        }
      }
    }
  }
}
