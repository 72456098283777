@import "../../../sass/_colors";

div#PublicProfile {
  background: $bkg;

  section.header {
    margin-top: 100px;

    div.Profile {
      span.photo {
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: #edeaea;
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }

      > div {
        max-width: 750px;
        min-width: 500px;
        width: 100%;
        position: relative;

        div.aboutMe {
          margin-bottom: 20px;
        }
        button.btn {
          position: absolute;
          top: 0;
          right: 0;
        }
        ul {
          list-style-type: none;

          li {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
              margin-left: 20px;
            }
          }
        }
        div.qualifications {
          margin-top: 40px;
          margin-bottom: 40px;

          div.Timeline {
            &:first-child {
              margin-left: 0 !important;
            }
            &:last-child {
              margin-left: 50px;
            }
          }
        }
        div.skills {
          margin-top: 5px;
          flex-wrap: wrap;

          > a.tag {
            margin-bottom: 12px;
            border-radius: 4px;
            padding: 4px 14px;
            background: $detailsLight;
            outline: none;
            white-space: nowrap;
            border: none;
          }
        }
      }
    }
  }

  section.listings {
    div.listingMentorContainer {
      margin-bottom: 65px;
      flex-wrap: wrap;
      justify-content: flex-start;

      div.CardPublicProfile {
        margin: 11px;
      }
    }
  }
}

@media (max-width: 768px) {
  div#PublicProfile {
    background: $bkg;

    section.header {
      margin-top: 100px;

      div.Profile {
        display: block;
        text-align: left !important;

        span.photo {
          display: block;
          margin: 0 auto;
        }

        > div {
          min-width: 100%;

          main {
            text-align: center;
          }

          p.rating {
            text-align: center;
            display: flex;
            justify-content: center;
            max-width: 200px;
            margin: 0 auto 20px;

            div.StarRatings {
              margin-left: 10px;
            }
          }

          div.aboutMe {
            margin-top: 50px;
          }
          button.btn {
            position: static;
            display: block;
            margin: 0 auto;
            max-width: 500px;
            width: 100%;
          }

          ul {
            display: block;
            li {
              &:last-child {
                margin-left: 0;
              }
            }
          }

          div.qualifications {
            display: block;

            div.Timeline {
              text-align: left !important;

              &:last-child {
                margin-top: 50px;
                margin-left: 0;
              }
            }
          }
          div.skills {
            margin-top: 5px;
            > a.tag {
              margin-bottom: 12px;
              button {
                border-radius: 4px;
                padding: 4px 14px;
                background: $detailsLight;
                outline: none;
                white-space: nowrap;
                border: none;
              }
            }
          }
        }
      }
    }

    section.listings {
      div.listingMentorContainer {
        margin-bottom: 65px;
        flex-wrap: wrap;
        justify-content: flex-start;

        div.CardPublicProfile {
          margin: 11px;
        }
      }
    }
  }
}
