@import "../../../sass/colors";

div#Session {
  background: $bkg;

  div.DeclineSession {
    z-index: 999999999 !important;
    div.wrap {
      span.photo {
        display: block;
        width: 120px;
        height: 120px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
        border-radius: 50%;
      }
      h4 {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0;
      }
      p.menteeName {
        text-align: center;
        font-weight: 600;
      }

      form.simple {
        margin-top: 40px;

        div.RadioButtom {
          margin-bottom: 40px;
        }

        > div.actions {
          margin: 0;
          padding: 0;
          align-items: center;
          p {
            text-transform: uppercase;
            margin-left: 30px;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0;
            opacity: 0.8;
            cursor: pointer;
            transition: 0.35s;

            &:hover {
              text-decoration: underline;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  div.EditRequestModal {
    z-index: 999999999;

    div.wrap {
      span.photo {
        display: block;
        width: 120px;
        height: 120px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
        border-radius: 50%;
      }
      h4 {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0;
      }
      p.menteeName {
        text-align: center;
        font-weight: 600;
      }

      form.simple {
        margin-top: 40px;

        > div.actions {
          margin: 0;
          padding: 0;
          align-items: center;
          p {
            text-transform: uppercase;
            margin-left: 30px;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
  div.ManageRequestModal {
    div.wrap {
      > div {
        span.photo {
          display: block;
          width: 120px;
          height: 120px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin: 0 auto;
          border-radius: 50%;
        }
        h4 {
          margin-top: 15px;
          margin-bottom: 0;
          text-align: center;
          font-weight: 700;
        }
        p.menteeName {
          font-weight: 600;
          text-align: center;
          margin-bottom: 30px;
        }

        > div.noBorder {
          border: none;
        }
        > div {
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 1px solid $details;

          strong {
            font-weight: 700;
          }

          p.question {
            font-weight: 600;
          }
          p.answer {
            font-size: 15px;
            font-weight: 500;
          }
          p.description {
            font-size: 13px;
          }
        }
        > div.actions {
          margin: 0;
          padding: 0;
          align-items: center;
          p {
            text-transform: uppercase;
            margin-left: 30px;
            font-weight: 600;
            font-size: 14px;
            color: #f03d3d;
            opacity: 0.8;
            letter-spacing: 0;
            transition: 0.35s;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .slick-arrow.slick-prev {
    display: none !important;
  }
  .slick-arrow.slick-next {
    background: #fff;
    border: 1px solid #e0deee;
    box-shadow: 0 8px 20px 0 rgba(51, 45, 97, 0.17);
    width: 60px;
    height: 60px;
    border-radius: 50%;

    svg {
      color: $blue;
    }
    &:before {
      display: none;
    }
  }

  > section {
    margin-top: 100px;
    margin-bottom: 200px;

    div.box {
      margin-bottom: 60px;
    }
  }
}

@media (max-width: 992px) {
  div#Session {
    > section {
      margin-bottom: 100px;
      div.box {
        margin-bottom: 60px;
      }
    }
  }
}
