section.becomeaMentor3Steps {
  margin-top: 200px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  button {
    display: block;
    max-width: 280px;
    width: 100%;
    margin: 40px auto;
  }

  article {
    justify-content: space-between;

    > div {
      text-align: center;

      > div {
        max-width: 280px;
        height: 300px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        img {
          height: 100%;
        }
      }

      h4,
      p {
        max-width: 280px;
        width: 100%;
        margin: 0 auto;
      }
      h4 {
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }

    > div.step1 {
      img {
        max-width: 270px;
      }
    }
    > div.step2 {
      img {
        max-width: 310px;
        height: auto;
      }
    }
    > div.step3 {
      img {
        max-width: 320px;
      }
    }
  }

  a.startMentoringNow {
    text-decoration: none;
  }
}

@media (max-width: 992px) {
  section.becomeaMentor3Steps {
    margin-top: 140px;
    article {
      display: block;

      > div {
        margin-bottom: 50px;

        > div {
          max-width: 100%;
          width: 100%;
          align-items: flex-end;

          img {
            width: 100%;
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
