div#Home {
  section.popularCategories {
    div.content {
      > h2 {
        text-align: center;
        margin-bottom: 10px;
      }
      > p {
        text-align: center;
        margin: 0 auto 50px;
        max-width: 600px;
      }

      > div.flex {
        flex-wrap: wrap;
        justify-content: center;

        a {
          display: block;
          max-width: 350px;
          width: 100%;
          height: 80px;
          border-radius: 4px;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
          overflow: hidden;
          margin: 15px;
          cursor: pointer;
          transition: box-shadow 0.35s;
          text-decoration: none;

          &:hover {
            box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.08);
          }

          article {
            display: flex;
            align-items: center;
            width: inherit;
            height: inherit;

            figure {
              width: 100px;
              height: 100%;
              display: block;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
            p {
              margin-left: 20px;
              font-size: 16px;
              font-weight: 700;
            }
          }
        }
      }

      > a {
        text-align: center;
        display: block;
        margin: 20px auto;
        color: $blue;
        font-weight: 700;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  div#Home {
    section.popularCategories {
      div.content {
        > div.flex {
          article {
            margin: 0 auto 30px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        > a {
          margin-top: 30px;
        }
      }
    }
  }
}
