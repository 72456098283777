div#Home {
  section.howItWorks {
    position: relative;
    padding-bottom: 100px;

    > img.effect1 {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
    }

    div.content {
      text-align: center;

      > h2 {
        margin-bottom: 10px;
      }

      > p {
        margin-bottom: 50px;
      }

      > div.flex {
        justify-content: space-between;
        text-align: center;

        div {
          &:first-child {
            margin-right: 25px;
          }
          &:last-child {
            margin-left: 25px;
          }

          figure {
            display: block;
            max-width: 350px;
            height: 310px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: auto;
              height: 100%;
            }
          }
          h3 {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          p {
            max-width: 270px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  div#Home {
    section.howItWorks {
      div.content {
        > div.flex {
          flex-direction: column;
          div {
            margin-bottom: 80px;

            &:last-child {
              margin-bottom: 0;
            }

            figure {
              margin: 0 auto;
              max-width: 300px;
              height: 100%;

              > img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
