section#career {
  margin-top: 90px;
  margin-bottom: 120px;
  div.content {
    h1 {
      text-align: center;
      width: 100%;
      max-width: 750px;
      margin: 0 auto 50px;
      display: block;
    }

    > div {
      display: flex;
      justify-content: space-between;

      div.left {
        margin-right: 50px;

        span.photo {
          width: 160px;
          height: 160px;
          min-width: 160px;
          min-height: 160px;

          border-radius: 50%;
          display: block;

          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        article {
          display: flex;
          align-items: center;
          padding-bottom: 100px;

          &:first-child {
            background-image: url(../../../assets/Pricing/career_effect_1.svg);
            background-repeat: no-repeat;
            background-position: 0 50%;

            span.photo {
              margin-right: 20px;
            }
          }
          &:last-child {
            padding-bottom: 0;
            position: relative;

            span.photo {
              margin-left: 20px;
            }
            img {
              position: absolute;
              bottom: -20%;
              right: -10%;
              z-index: -1;
            }
          }

          p {
            max-width: 232px;
            width: 100%;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 26px;
          }
        }
      }
      div.right {
        max-width: 620px;
        ul {
          list-style: none;
          padding-left: 30px;

          li {
            font-size: 20px;
            letter-spacing: 0;
            line-height: 30px;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 35px;
            }
            &:before {
              content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
              color: rgba($black, 0.9); /* Change the color */
              font-weight: bold; /* If you want it to be bold */
              display: inline-block; /* Needed to add space between the bullet and the text */
              width: 1em; /* Also needed for space (tweak if needed) */
              margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
          }
        }
        > div {
          p {
            margin-bottom: 20px;
          }
          figure {
            display: flex;
            align-items: center;
            img {
              margin-left: 45px;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }

      div#mobile {
        display: none;
      }
    }
  }
}

@media (max-width: 992px) {
  section#career {
    margin-top: 0;

    div.content {
      > div {
        flex-direction: column;

        div.left {
          order: 2;
          margin: 0 auto;
          text-align: center;

          span.photo {
            width: 140px;
            height: 140px;
            min-width: 140px;
            min-height: 140px;
            margin-bottom: 15px;
          }

          article {
            flex-direction: column;
            padding-bottom: 50px;

            &:first-child {
              background-image: url(../../../assets/Pricing/career_effect_1.svg);
              background-repeat: no-repeat;
              background-position: -100% 20%;

              span.photo {
                margin-right: 20px;
              }
            }
            &:last-child {
              padding-bottom: 0;
              position: relative;

              span.photo {
                margin-left: 20px;
                order: 1;
              }
              p {
                order: 2;
              }
              img {
                bottom: auto;
                top: 0;
                right: auto;
                left: 0;
              }
            }

            p {
              max-width: 232px;
              width: 100%;
              font-size: 20px;
              letter-spacing: 0;
              line-height: 30px;
            }
          }
        }
        div.right {
          order: 1;
          max-width: 620px;
          margin: 0 auto;
          > div {
            display: none;
          }
        }
        div#mobile {
          display: block;
          order: 3;
          text-align: center;
          margin-top: 50px;
          p {
            margin-bottom: 30px;
          }

          figure {
            img {
              display: block;
              margin: 20px auto;
            }
          }
        }
      }
    }
  }
}
