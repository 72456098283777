section#testimonialSection {
  div.content {
    > h2,
    > p {
      text-align: center;
    }

    article {
      margin-top: 80px;
      margin-bottom: 100px;

      .slick-track {
        display: flex;
        .slick-slide {
          margin: 20px;
        }
      }

      div.card {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 15px;
        max-width: 400px;
        width: 100%;
        position: relative;
        outline: none;

        > div.quotation {
          position: absolute;
          top: -15px;
          right: -15px;
          width: 36px;
          height: 36px;
          background: $blue;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        h4 {
        }
        > p {
          margin-top: 6px;
          margin-bottom: 20px;
        }

        > div {
          display: flex;
          > span.photo {
            display: block;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          p {
            margin-left: 20px;
            span {
              display: block;
              &:first-child {
                font-weight: 600;
                font-size: 18px;
              }
              &:last-child {
                line-height: 23px;
                font-weight: 500;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
