@import "../../../sass/_colors";
@import "../../../sass/_mixins";

div#FindMentor {
  background: $bkg;

  section.findMentorContainer {
    margin-top: 50px;

    div.content {
      > h2 {
        margin-bottom: 50px;
      }

      div.search {
        button.showFilter {
          display: none;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          padding: 20px;
          position: fixed;
          top: 80%;
          transform: translateY(-50%);
          right: 0;

          > span {
            text-transform: uppercase;
            position: absolute;
            bottom: -25px;
            right: 12px;
            color: black;
            font-weight: 600;
          }

          > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }

        div.Filter {
          margin-right: 25px;

          div.filterInput {
            > p {
              height: 40px;
            }
          }

          > p {
            justify-content: space-between;
            align-items: center;

            span {
              margin: 0;
              padding: 0;
              display: block;

              &:first-child {
                font-weight: 600;
              }

              //CLEAR ALL
              &:last-child {
                color: $blue;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.5px;
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        div.MentorList {
          width: 100%;
          margin-left: 25px;

          > h4 {
            margin-bottom: 20px;
          }

          > button {
            max-width: 260px;
            width: 100%;
            display: block;
            margin: 60px auto;
          }
        }
      }
    }
  }
}

form.FilterForm {
  margin-bottom: 50px;

  div.FilterBox {
    background: #fff;
    border: 1px solid $details;
    border-radius: 4px;
    padding: 15px;
    min-width: 270px;
    max-height: 350px;
    overflow-y: auto;
    position: relative;

    margin-bottom: 20px;

    &:first-child {
      margin-top: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    > strong {
      display: block;
      font-weight: 600;
    }
    button {
      padding: 0;
      width: 25px;
      height: 25px;
      cursor: pointer;
      position: absolute;
      top: 13px;
      right: 5px;
      background: transparent;
      outline: none;
      border: none;
    }

    > div {
      .Checkbox {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .Checkbox.hide {
        display: none;
      }
    }
  }

  > div.input-range {
    max-width: 215px;
    margin: 35px auto 35px;
  }
  > button[type="submit"] {
    width: 100%;
  }
}

@media (max-width: 900px) {
  div#FindMentor {
    section.findMentorContainer {
      div.content {
        div.search {
          flex-direction: column;

          div.Filter {
            margin: 0 auto;
            width: 100%;
          }

          div.MentorList {
            margin: 0 auto;
          }
        }
      }
    }
  }

  form.FilterForm {
    div.FilterBox {
      min-width: auto;
    }

    > div.input-range {
      max-width: 769px;
      margin: 35px auto 35px;
    }
  }
}

@media (max-width: 768px) {
  div#FindMentor {
    section.findMentorContainer {
      div.content {
        div.search {
          button.showFilter {
            display: block;
          }
        }
      }
    }
  }
}
