@import "../../../sass/colors";

div.CardMentor {
  background: #ffffff;
  box-shadow: 0 12px 34px 0 rgba(75, 68, 128, 0.13);
  border-radius: 8px;
  padding: 25px;
  max-width: 360px;

  .mentorInfo {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 25px;
    margin-bottom: 15px;
    position: relative;

    span.favourite {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    div.left {
      margin-right: 25px;

      span.photo {
        width: 72px;
        height: 72px;
        display: block;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background: #edeaea;
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }
      p.price {
        margin-top: 10px;
        color: $blue;
        font-size: 14px;
        font-weight: 700;
        small {
          color: $blue;
        }
      }
    }

    div.right {
      p.name {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
      }
      ul {
        list-style-type: none;
        li {
          display: flex;
          align-items: center;

          margin-top: 12px;
          font-size: 14px;
          > svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
  .listingInfo {
    > h4 {
      margin-bottom: 20px;
    }
    > div {
      flex-wrap: wrap;

      a.tag {
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        padding: 4px 14px;
        background: $detailsLight;
        outline: none;
        white-space: nowrap;
        border: none;
      }
    }
    > a {
      display: block;
      margin-top: 15px;
    }
  }
}

div.CardMentor.on {
  .mentorInfo {
    div.left {
      span.photo {
        &:after {
          background: #25d786;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  div.CardMentor {
    margin: 10px;

    .mentorInfo {
      display: block;

      div.left {
        margin-right: 0;

        span.photo {
          margin: 0 auto;
        }
        p.price {
          text-align: center;
        }
      }
    }
  }
}
