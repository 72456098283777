section.WhatIsinForYou {
  max-width: 900px;
  margin: 0 auto;

  h2 {
    text-align: center;
    margin-bottom: 60px;
  }

  .w1 {
    img {
      max-width: 340px;
      width: 100%;
    }
  }
  .w2 {
    img {
      max-width: 400px;
      width: 100%;
    }
  }
  .w3 {
    margin-bottom: 0;

    img {
      max-width: 320px;
      width: 100%;
    }
  }

  article {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
    margin-top: 100px;

    > div {
      p.subtitle {
        font-weight: 600;
        margin-bottom: 12px;
      }
      max-width: 390px;
    }
  }
}

@media (max-width: 992px) {
  section.WhatIsinForYou {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;

    h2 {
      text-align: center;
      margin-bottom: 60px;
    }

    .w1 {
      img {
        left: -30px;
      }
    }
    .w2 {
      > div {
        order: 2;
      }
      img {
        left: 30px;
      }
    }
    .w3 {
      img {
        left: -30px;
      }
    }

    article {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-bottom: 100px;
      margin-top: 100px;

      > img {
        position: relative;
      }

      > div {
        p.subtitle {
          margin-top: 20px;
        }
      }
    }
  }
}
