section#header {
  margin-top: 100px;
  padding-bottom: 120px;
  position: relative;

  > img#effect_header_2 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  div.content {
    > h1,
    > h2 {
      text-align: center;
    }
    > h2 {
      font-family: "Source Sans Pro", sans-serif;
      margin-top: 20px;
      font-size: 30px;
      color: #6c688f;
      line-height: 72px;
    }
    > h1 {
      max-width: 1000px;
      margin: 0 auto;
    }

    div.plan {
      display: flex;

      div.monthlyPlan.free {
        background-image: url(../../../assets/Pricing/effect_plan_free.svg);

        ul {
          li {
            &:before {
              content: url(../../../assets/Pricing/mark_free.svg);
            }
          }
        }

        button {
          background: #c9c6e2;
          box-shadow: 0 4px 15px 0 #c9c6e2;
          &:hover {
            box-shadow: 0 10px 34px 0 #c9c6e2;
          }
        }
      }
      div.monthlyPlan {
        background: #ffffff;
        background-image: url(../../../assets/Pricing/effect_plan.svg);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;

        border: 1px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        max-width: 400px;
        width: 100%;
        margin: 50px auto;
        padding: 25px 30px;

        position: relative;

        > p {
          font-family: "Lora", serif;
          font-weight: 700;
          font-size: 22px;
          color: #ffffff;
          margin: 0;
        }

        > div {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;

          p {
            span.title {
              font-weight: 700;
              font-size: 30px;
            }
            span.desc {
              font-weight: 500;
              font-size: 16px;
              color: #6c688f;
            }
            span {
              display: block;
              margin: 0;
            }
          }
        }

        ul {
          margin-top: 30px;
          margin-bottom: 50px;
          list-style: none;

          li {
            position: relative;
            padding-left: 35px;
            margin-bottom: 22px;

            font-weight: 400;
            font-size: 18px;
            line-height: 25px;

            &:last-child {
              margin-bottom: 0;
            }

            &:before {
              content: url(../../../assets/Pricing/mark.svg);
              display: block;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        > a {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(50%);
        }
      }

      > div {
        &:first-child {
          margin-right: 25px;
        }
        &:last-child {
          margin-left: 25px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  section#header {
    margin-top: 30px;
    padding-bottom: 80px;
    position: relative;

    > img#effect_header_2 {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    div.content {
      > h1,
      > h2 {
        text-align: center;
      }
      > h2 {
        margin-top: 20px;
      }
      > h1 {
        max-width: 1000px;
        margin: 0 auto;
      }

      div.plan {
        display: block;

        div.monthlyPlan {
          margin: 50px auto !important;
        }
      }
    }
  }
}
