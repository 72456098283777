div.selectBox {
  display: block !important;

  > p {
    margin-bottom: 12px;
    font-weight: 600;
    line-height: 19px;

    span:last-child {
      font-size: 12px;
      color: rgba($black, 0.7);
      display: block;
    }
  }

  > div {
    display: flex;

    > div.active {
      background: $blue;
      border-color: transparent;
      span {
        color: #fff;
      }
      small {
        color: #fff;
      }
    }
    > div {
      border: 1px solid $details;
      text-align: center;
      padding: 10px 30px;
      margin-left: 15px;
      cursor: pointer;
      border-radius: 2px;
      max-width: 125px;
      width: 100%;

      &:first-child {
        margin-left: 0;
      }

      span {
        line-height: 22px;
      }
      small {
        font-weight: 700;
      }
    }
  }
}
@media (max-width: 500px) {
  div.selectBox {
    > div {
      display: block;

      > div {
        max-width: 100%;
        width: 100%;
        margin: 15px auto;

        &:first-child {
          margin-top: 0;
        }

        span {
          line-height: 22px;
        }
      }
    }
  }
}
