.content {
  max-width: 1200px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto 0;
}

.flex {
  display: flex;
}
