@import "../../../sass/colors";

div.MentoryBox {
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(75, 68, 128, 0.11);
  border-radius: 6px;

  > p.title {
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid $detailsLight;
    padding: 20px;
    padding-left: 40px;
  }

  > div.container {
    padding: 40px;
  }
}

@media (max-width: 468px) {
  div.MentoryBox {
    > p.title {
      padding-left: 20px;
    }
    > div.container {
      padding: 20px;
    }
  }
}
