@import "../../../sass/_colors";

.slick-arrow.slick-next {
  background: #fff;
  border: 1px solid #e0deee;
  box-shadow: 0 8px 20px 0 rgba(51, 45, 97, 0.17);
  width: 60px;
  height: 60px;
  border-radius: 50%;

  svg {
    color: $blue;
  }
  &:before {
    display: none;
  }
}

div.SliderMentory {
  .slick-slide {
    div.CardMentor {
      box-shadow: none;
      transition: box-shadow 1s;
    }
  }

  .slick-slide.slick-active {
    div.CardMentor {
      box-shadow: 4px 6px 10px rgba(black, 0.1);
    }
  }

  .slick-list {
  }
  .slick-arrow.slick-prev {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  div.SliderMentory {
    > div {
      margin: 0 auto;
      max-width: 800px;
    }
  }
}

@media (max-width: 870px) {
  div.SliderMentory {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;

    div.FeaturedMentorCard {
      margin-left: 25px;
      margin-right: 25px;
    }

    .slick-arrow.slick-next {
      //display: none !important;
    }
  }
}

@media (max-width: 400px) {
  div.SliderMentory {
    > div {
      div.slick-active {
        div.FeaturedMentorCard {
          box-shadow: 0 8px 20px 0 rgba(51, 45, 97, 0.17);
        }
      }
      div.FeaturedMentorCard {
        margin: 0;
        box-shadow: none;
      }
    }
  }
}
