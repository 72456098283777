@import "../../../sass/_colors";

div#becomeaMentor {
  background-image: url(../../../assets/BecomeaMentor/header.svg);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 100%;

  @import "./header";
  @import "./_companies";
  @import "./_WhatIsinForYou";
  @import "./_becomeaMentor3Steps";
  @import "./_faq";
  @import "./SignUpSection";
}

@media (max-width: 992px) {
  div#becomeaMentor {
    background: none;
  }
}
