.noScroll {
  overflow: hidden;
}

//Photo on
span.photo.on {
  &:after {
    background: #25d786 !important;
  }
}

//Slick dots
ul.slick-dots {
  li {
    margin: 0;

    button {
      &:before {
        opacity: 1;
        color: $secondary;
        font-size: 12px;
      }
    }
  }
  li.slick-active {
    button {
      &:before {
        opacity: 1;
        color: $blue;
      }
    }
  }
}

//Tag
a.tag {
  margin-right: 12px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 7px 20px;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

//Links
a.primaryLink {
  color: $blue;
  text-decoration: none;
  opacity: 0.95;
  transition: 0.35s;

  &:hover {
    opacity: 1;
    text-decoration: underline !important;
  }
}

//Help classes
.flexCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//DropdownOpt
ul.dropdownOpt.active {
  display: block;
  z-index: 99999;
}
ul.dropdownOpt {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border: 1px solid $detailsLight2;
  box-shadow: 4px 4px 4px rgba(black, 0.05);
  border-radius: 4px;
  list-style-type: none;

  li {
    padding: 8px 20px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 600;
    &:hover {
      background: $detailsLight;
    }
  }
  li.link {
    padding: 0;
    &:hover {
      background: transparent;
      a {
        background: $detailsLight;
      }
    }
    a {
      text-decoration: none;
      padding: 8px 20px;
      font-size: 14px;
      white-space: nowrap;
      font-weight: 600;
      width: 100%;
      display: block;
    }
  }
}

//Profile Settings
div.Profile {
  max-width: 950px;
  margin: 0 auto 100px;

  > span.photo {
    width: 150px;
    height: 150px;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 50px;
  }

  > div {
    p.location {
      opacity: 0.7;
      margin-bottom: 20px;
    }
    div.aboutMe {
      margin-top: 20px;

      p {
        &:first-child {
          margin-bottom: 5px;
          font-weight: 600;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  div.Profile {
    display: block;
    text-align: center;
    margin-bottom: 50px;

    > span.photo {
      margin: 0 auto;
    }

    > div {
      div.StarRatings {
        margin: 5px auto;
        max-width: 110px;
      }
    }
  }
}

//linkedin OAuth component
div.LinkedinOAuth {
  button {
    font-family: "Source Sans Pro", sans-serif;
    background: #0077b5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px auto 70px;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 !important;
    text-transform: initial !important;

    &:hover {
      background: #0077b5;
    }

    span {
      border-left: 1px solid rgba(white, 0.3);
      color: #fff;
      padding: 12px 10px;
    }
    svg {
      width: 30px;
      display: block;
      margin-left: 10px;
      padding-right: 10px;
    }
  }
}
