div.Modal.ModalSetCurrency {
  z-index: 9999;

  div.wrap {
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 60px !important;
    padding-top: 60px !important;

    h2 {
      text-align: center;
      margin-top: 10px;
    }
    > p {
      text-align: center;
      max-width: 300px;
      margin: 5px auto 20px;
    }
    div.Dropdown {
      max-width: 350px;
      margin: 0 auto 20px;

      ul {
        max-height: 120px;
      }
    }

    div.preloader-wrapper {
      display: block;
      margin: 0 auto;
    }
    button {
      display: block;
      max-width: 350px;
      width: 100%;
      margin: 0 auto;
    }

    > svg {
      display: block;
      margin: 0 auto;
      color: #ffae42;
    }
  }
}
