div#Page404 {
  > div {
    text-align: center;
    margin-top: 120px;
    margin-bottom: 100px;

    h1 {
      margin-bottom: 20px;
    }
    p {
      max-width: 500px;
      display: block;
      margin: 0 auto 15px;
    }
    button {
    }
  }

  footer.footer {
    width: 100%;
  }
}
