@import "../../../sass/_colors";

div.CardFindMentor.on {
  .container {
    > div.flex {
      div.first {
        span.photo {
          &:after {
            background: #25d786;
          }
        }
      }
    }
  }
}

div.CardFindMentor.blocked {
  position: relative;

  > a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9;
  }
  div.container {
    -webkit-filter: blur(4px); /* Safari 6.0 - 9.0 */
    filter: blur(4px);
  }
}
div.CardFindMentor {
  margin-bottom: 30px;
  max-width: 850px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 150px;
  }
  &:hover {
    div.container {
      transition: box-shadow 0.2s;
      box-shadow: 2px 12px 20px 0 rgba(75, 68, 128, 0.2);
    }
  }

  div.container {
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    box-shadow: 0 6px 14px 0 rgba(75, 68, 128, 0.13);
    border-radius: 6px;
    transition: box-shadow 0.2s;

    > div.flex {
      div.first {
        margin-right: 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 14%;

        span.photo {
          width: 90px;
          height: 90px;
          display: block;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background: #edeaea;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            border: 3px solid #fff;
          }
        }

        > p.price {
          white-space: nowrap;
          margin-top: 12px;
          color: $blue;
          font-weight: 700;
          small {
            color: $blue;
          }
        }
      }
      div.middle {
        margin-right: 10px;
        width: 100%;

        > ul {
          margin-top: 15px;
          margin-bottom: 10px;
          list-style-type: none;
          max-width: 300px;

          li.flex {
            justify-content: space-between;
            flex-wrap: wrap;
            span {
              display: flex;
              margin-bottom: 12px;
            }
          }
          li {
            display: flex;
            align-items: center;

            font-size: 14px;
            svg {
              display: block;
              margin-right: 8px;
            }
          }
        }

        div.StarRatings {
          margin-top: 8px;
        }
      }
      div.third {
        flex-direction: column;
        align-items: center;

        button {
          margin-bottom: 15px;
          white-space: nowrap;
          min-width: 165px;
        }
        > p.favourite {
          font-size: 13px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          cursor: pointer;

          span,
          svg {
            color: $blue;
          }
          span {
            font-weight: 700;
            padding-left: 5px;
          }
        }
      }
    }

    div.infoCardFindMentor {
      > div {
        flex-wrap: wrap;

        > a.tag {
          margin-top: 12px;
          border-radius: 4px;
          padding: 4px 14px;
          background: $detailsLight;
          outline: none;
          white-space: nowrap;
          border: none;
        }
      }

      p.desc {
        border-top: 1px solid #ccc;
        padding-top: 10px;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 700px) {
  div.CardFindMentor {
    margin-bottom: 30px;
    max-width: 400px;

    div.container {
      > div.flex {
        display: block;

        div.first {
          margin: 0 auto;
        }
        div.middle {
          margin: 10px auto;
        }
        div.third {
          flex-direction: column;
          align-items: center;

          > button {
            max-width: 100%;
            width: 100%;
            margin: 12px auto 15px;
            white-space: nowrap;
          }
          > p.favourite {
            font-size: 13px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            cursor: pointer;

            span,
            svg {
              color: $blue;
            }
            span {
              font-weight: 700;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
