@import "../../../sass/colors";

@import "./_aboutYou";
@import "./_billing";

div#Settings {
  background: $bkg;
  min-height: 100vh;

  > section {
    padding-top: 100px;
    padding-bottom: 100px;

    div.content {
      justify-content: space-between;

      aside {
        margin-right: 25px;

        span.photo {
          display: block;
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          overflow: hidden;
          margin: 0 auto;

          &:after {
            font-family: "Source Sans Pro", sans-serif;
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 0.5px;
            content: "Edit";
            color: #fff;
            background: rgba(black, 0.4);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.35s;
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }

          input {
            display: none;
          }
        }

        p.subtitle {
          margin-top: 10px;
          font-weight: 600;
          text-align: center;
        }

        ul {
          background: #fff;
          box-shadow: 0 4px 8px 0 rgba(75, 68, 128, 0.11);
          border-radius: 6px;
          list-style: none;
          margin-top: 25px;
          min-width: 250px;

          li {
            a.active {
              color: $blue;
              svg {
                opacity: 1;
              }
            }
            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              text-decoration: none;
              padding: 20px 25px;
              padding-right: 10px;
              border-bottom: 1px solid $details;
              transition: color 0.35s;
              cursor: pointer;
              font-size: 18px;

              svg {
                opacity: 0;
              }

              &:hover {
                color: $blue;
              }
            }
          }
        }
      }

      article {
        max-width: 780px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 850px) {
  div#Settings {
    > section {
      padding-top: 80px;
      padding-bottom: 80px;
      max-width: 650px;
      margin: 0 auto 0;

      div.content {
        flex-direction: column;
        justify-content: center;

        aside {
          margin: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  div#Settings {
    > section {
      padding-bottom: 50px;

      div.content {
        justify-content: space-between;
      }
    }
  }
}

article#changePassword {
  p.textSecondary {
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: 0;
    opacity: 0.9;

    span:last-child {
      color: $blue;
      cursor: pointer;
    }
  }
}

//Stripe integration
/**
* The CSS shown here will not be introduced in the Quickstart guide, but
* shows how you can use CSS to style your Element's container.
*/
form#paymenMethodForm {
  > div {
    position: relative;
    margin-bottom: 20px;

    span.brand {
      position: absolute;
      top: 39%;
      right: 10px;
      img {
        max-width: 44px;
      }
    }

    label {
      display: block;
      margin-bottom: 10px;
    }

    input,
    .StripeElement {
      color: #32325d;
      background-color: white;
      border: 1px solid $details;
      border-radius: 4px;
      padding: 14px;

      box-shadow: 0 1px 3px 0 #e6ebf1;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;
    }

    input:focus,
    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
      border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
      background-color: #fefde5 !important;
    }
  }
  p.alert {
    font-size: 12px;
    line-height: 23px;
    color: #fa755a;
    margin-bottom: 30px;
  }
}
