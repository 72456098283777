header {
  text-align: center;
  margin-top: 160px;

  padding-left: 25px;
  padding-right: 25px;

  p.subtitle {
    margin-top: 16px;
    margin-bottom: 30px;
  }

  button {
    max-width: 280px;
    width: 100%;
    color: #fff;
    span {
      color: #fff;
    }
  }
}

@media (max-width: 992px) {
  header {
    margin-top: 0;
    background-image: linear-gradient(145deg, #ff6236 21%, #ff1081 100%);
    padding-top: 100px;
    padding-bottom: 100px;

    h1 {
      color: #fff;
    }
    p {
      color: rgba(white, 0.8);
    }
  }
}
