@import "../../../sass/colors";

.tooltip-container {
  background: $blue;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  border: none;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  padding: 10px;

  .tooltip-arrow[data-placement="top"] {
    &:after {
      border-color: $blue transparent transparent transparent;
    }
    &:before {
      border-color: $blue transparent transparent transparent;
    }
  }
  .tooltip-arrow[data-placement="right"] {
    &:after {
      border-color: transparent $blue transparent transparent;
    }
    &:before {
      border-color: transparent $blue transparent transparent;
    }
  }
  .tooltip-arrow[data-placement="left"] {
    &:after {
      border-color: transparent transparent transparent $blue;
    }
    &:before {
      border-color: transparent transparent transparent $blue;
    }
  }
  .tooltip-arrow[data-placement="bottom"] {
    &:after {
      border-color: transparent transparent $blue transparent;
    }
    &:before {
      border-color: transparent transparent $blue transparent;
    }
  }
}
