div.ReportUser {
  z-index: 99999 !important;

  div.wrap {
    span.photo {
      display: block;
      width: 120px;
      height: 120px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto;
      border-radius: 50%;
    }
    h4 {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 0 !important;
    }
    p.menteeName {
      text-align: center;
      font-weight: 600;
    }

    form.simple {
      margin-top: 30px;

      div.RadioButtom {
        margin-bottom: 40px;
      }

      > div.actions {
        margin: 0;
        padding: 0;
        align-items: center;
        p {
          text-transform: uppercase;
          margin-left: 30px;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0;
          cursor: pointer;
          transition: 0.35s;
          opacity: 0.8;

          &:hover {
            opacity: 1;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
