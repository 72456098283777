@import "../../../sass/colors";

@import "./_UserLoggedDesktop";
@import "./_UserLoggedMobile";
@import "./_UserLoggedOutMobile";

nav.Navigation {
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  z-index: 9;

  div.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;

    > img {
      width: 152px;
      height: 100%;
    }

    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include UserLoggedDesktop();

      li.notification {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: -2px;
          right: -8px;
          width: 6px;
          height: 6px;
          display: block;
          background: #ff0000;
          border-radius: 50%;
        }
      }
      li {
        margin-left: 48px;

        &:last-child {
          margin-left: 30px;
        }

        a {
          outline: none;
          text-decoration: none;
          color: #fff;
          transition: 0.35s;

          &:hover {
            color: $blue;
          }
        }
      }
    }

    span.btnMenu {
      display: none;
      cursor: pointer;
    }
  }

  > nav.MenuMobile.active {
    transform: translateX(0);
  }
  > nav.MenuMobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999999;
    background: #fff;

    overflow-y: scroll;
    overflow-x: hidden;

    transition: transform 0.3s;
    transform: translateX(-100%);

    width: 100%;
    height: 100vh;

    @include UserLoggedOutMobile();
    @include UserLoggedMobile();
  }
}

nav.Navigation.stylish {
  background: #fff;
  box-shadow: 0 3px 8px 0 rgba(51, 45, 97, 0.12);

  div.content {
    > ul {
      > li {
        > a {
          color: $black;
          transition: 0.35s;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}

nav.Navigation.stylish2 {
  div.content {
    > ul {
      > li {
        > a {
          color: $black;
          transition: 0.35s;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  nav.Navigation {
    div.content {
      padding-left: 25px;
      padding-right: 25px;

      > ul {
        display: none;
      }
      span.btnMenu {
        display: block;
      }
    }
  }
}
