section.faq {
  margin-top: 200px;
  position: relative;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  div.content {
    padding-bottom: 200px;

    > h2 {
      text-align: center;
      margin-bottom: 40px;
    }
    article {
      display: flex;
      justify-content: space-between;

      > div {
        max-width: 100%;
        width: 100%;

        &:last-child {
          margin-left: 25px;
        }
        &:first-child {
          margin-right: 25px;
        }

        div.QuestionFAQ {
          width: 100%;
          flex-shrink: 0;
          height: auto;
        }
      }
    }
    > a {
      display: block;
      margin: 50px auto 0;
      text-align: center;
    }
  }
}

@media (max-width: 992px) {
  section.faq {
    margin-top: 150px;

    div.content {
      padding-bottom: 100px;

      article {
        display: block;

        > div {
          margin: 0 auto;

          &:last-child {
            margin-left: 0;
          }
          &:first-child {
            margin-right: 0;
          }
        }
      }
      > button {
        max-width: 300px;
        width: 100%;
      }
    }
  }
}
