@import "../../../sass/colors";

div#Signup {
  > section {
    min-height: 100vh;

    div.content {
      text-align: center;
      display: flex;

      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;

      h2 {
        margin-bottom: 50px;
      }

      a.back {
        position: absolute;
        top: 25px;
        right: 25px;
      }

      > div {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 100px;

        a {
          color: $blue;
          text-decoration: none;
          font-weight: 700;
        }

        form.simple {
          button {
            margin-top: 10px;
            width: 100%;
          }
        }

        div.footer {
          p {
            &:first-child {
              margin-top: 65px;
            }
            &:last-child {
              margin-bottom: 70px;
            }
          }
          button {
            margin: 10px auto 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  div#Signup {
    > section {
      div.content {
        a.back {
          display: none;
        }
      }
    }
  }
}
