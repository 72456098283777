div#Home {
  section.simpleToUseAndUnderstand {
    margin-top: 100px;
    padding-top: 100px;
    margin-bottom: 200px;
    position: relative;

    > img.effect1 {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    div.content {
      > div.flex {
        justify-content: space-between;

        > div {
          &:first-child {
            max-width: 620px;
            width: 100%;
            height: 320px;
            margin-right: 25px;

            iframe {
              width: 100%;
              height: 100%;
            }
          }
          &:last-child {
            max-width: 430px;
            margin-left: 25px;

            > p {
              margin-top: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  div#Home {
    section.simpleToUseAndUnderstand {
      margin-top: 0;
      padding-top: 100px;
      margin-bottom: 150px;
      position: relative;

      div.content {
        > div.flex {
          flex-direction: column;

          > div {
            &:first-child {
              margin: 0 auto;
            }
            &:last-child {
              max-width: 500px;
              margin: 30px auto;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
