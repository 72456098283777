section.companies {
  text-align: center;
  margin-top: 160px;
  margin-bottom: 200px;

  p {
    font-size: 20px;
  }

  div.flex {
    max-width: 900px;
    margin: 40px auto 0;
    justify-content: space-between;

    > img {
      opacity: 0.6;
    }
  }
}

@media (max-width: 992px) {
  section.companies {
    margin-top: 80px;
    margin-bottom: 120px;

    div.flex {
      margin: 30px auto 0;
    }
  }
}

@media (max-width: 800px) {
  section.companies {
    div.flex {
      flex-direction: column;
      margin: 30px auto 0;
      > img {
        margin-bottom: 50px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
