@import "../../../../sass/_colors";

.input-range {
  .input-range__label.input-range__label--max,
  .input-range__label.input-range__label--min {
    display: none !important;
  }

  .input-range__track {
    background: $details;
  }
  .input-range__track--active {
    background: $blue;
  }
  .input-range__track {
    height: 0.5rem;
  }

  .input-range__slider-container {
    font-family: "Source Sans Pro", sans-serif;
    color: $black;
    font-weight: 600;
  }

  .input-range__slider {
    background: #fff;
    border: 1px solid $details;
    height: 20px;
    width: 20px;
    margin-top: -0.85rem;
  }

  .input-range__label--value {
    top: -2rem;
  }
}
