@import "../../../sass/colors";

div.Card.noBorder {
  border: none;
}
div.Card {
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $detailsLight;
  padding-bottom: 20px;
  padding-top: 20px;

  figure {
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }

    p {
      line-height: 19px;

      span.cardNumber {
        font-weight: 600;
        font-size: 16px;
      }
      span.default {
        font-size: 13px;
        cursor: pointer;
      }
      span.default.defaultPaymentMethod {
        color: $blue;
        font-weight: 600;
        cursor: auto;
      }
    }
    span {
      display: block;
    }
  }
  > span.delete {
    cursor: pointer;
    padding: 2px;
  }
}
