@import "../../../../sass/_colors";

div.MyFavorite {
  display: flex;
  background: #fff;
  box-shadow: 0 6px 14px 0 rgba(75, 68, 128, 0.13);
  border-radius: 6px;
  max-width: 550px;
  width: 100%;
  position: relative;
  padding: 28px;

  span.favorite {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    cursor: pointer;
    svg {
      color: $blue;
    }
  }

  > div {
    &:first-child {
      margin-right: 25px;
      text-align: center;

      span.photo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        flex-shrink: 0;
        display: block;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      p.price {
        color: $blue;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 12px;
        font-size: 16px;
        small {
          color: $blue;
        }
      }
    }

    &:last-child {
      h4 {
        margin-bottom: 5px;
        max-width: 220px;
      }
      p.name {
        font-size: 14px;
        font-weight: 600;
      }
      > ul {
        margin-top: 20px;
        outline: none;
        list-style-type: none;
        li {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          svg {
            margin-right: 15px;
          }
        }
      }
      button {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  div.MyFavorite {
    display: block;

    div {
      &:first-child {
        margin-right: 0;

        span.photo {
          margin: 0 auto;
        }
      }

      &:last-child {
        button {
          margin-top: 30px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
