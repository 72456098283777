section.SignUpSection {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../assets/bkg.svg);
  text-align: center;

  margin-top: 120px;

  padding-bottom: 150px;
  padding-top: 150px;

  div.content {
    > h2 {
      color: #fff;
      margin-bottom: 15px;
    }
    > p {
      color: #fff;
      max-width: 610px;
      margin: 0 auto 40px;
    }

    form {
      max-width: 670px;
      margin: 0 auto;
      button {
        max-width: 180px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  section.SignUpSection {
    padding-top: 80px;
    padding-bottom: 80px;

    div.content {
      > h2 {
        color: #fff;
        margin-bottom: 15px;
      }
      > p {
        color: #fff;
        max-width: 610px;
        margin: 0 auto 40px;
      }

      form {
        max-width: 670px;
        margin: 0 auto;
      }
    }
  }
}
