//Colors

//Primary
$blue: #4663f6;
$secondary: #d5d3e6;
$black: #484848;
$primaryDegrade: linear-gradient(156deg, #ff6236 0%, #ff1081 100%);

//Secondary
$bkg: #f6f5fa;
$details: #c9c6e2;
$detailsLight: #ecebf5;
$detailsLight2: #e7e6eb;
