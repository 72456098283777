@import url("https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lora", serif;
  color: rgba(0, 0, 0, 0.85);
}

h1 {
  font-size: 54px;
  font-weight: 700;
  line-height: 68px;
}

h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 61px;
}
@media (max-width: 600px) {
  h2 {
    font-size: 35px;
    line-height: 43px;
  }
}

h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
}

h4 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

p,
span,
strong,
i,
b,
li,
a,
label,
small {
  font-family: "Source Sans Pro", sans-serif;
  color: $black;
}

p.subtitle {
  font-size: 24px;
  line-height: 36px;
  color: $black;
}
@media (max-width: 600px) {
  p.subtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
p {
  font-size: 16px;
  line-height: 27px;
}
