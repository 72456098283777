div#PrivacyPolicy {
  background-color: #f5f5f5;

  > div.content {
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 20px;

    h1,
    h2,
    h3 {
      color: #333;
    }

    h1 {
      font-size: 2em;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 1.5em;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 1.2em;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }

    ul {
      list-style-type: disc;
      margin-left: 20px;
    }

    li {
      margin-bottom: 5px;
    }
  }
}
