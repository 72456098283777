.btn {
  text-transform: uppercase;
  padding: 15px 35px;
  background: rgba($blue, 0.96);
  box-shadow: 0 4px 15px 0 rgba(70, 99, 246, 0.45);
  border-radius: 42px;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  border: none;
  cursor: pointer;
  font-size: 13px;
  outline: none;
  transition: box-shadow 0.35s;

  &:hover {
    box-shadow: 0 10px 34px 0 rgba(70, 99, 246, 0.35);
    background: rgba($blue, 1);
  }
}

.btn.large {
  padding: 22px 33px;
  font-size: 15px;
}

.btn.white {
  border: 1px solid #e0deee;
  padding: 12px 35px;
  background: #fff;
  color: $blue;
  box-shadow: 4px 4px 4px rgba(black, 0.02);
  border-radius: 20px;
  font-size: 13px;

  &:hover {
    box-shadow: 5px 5px 25px rgba(black, 0.08);
  }
}
