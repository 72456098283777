div#Home {
  section.featuredMentor {
    margin-top: 140px;
    padding-bottom: 200px;
    position: relative;

    img.effect1,
    img.effect2 {
      position: absolute;
      z-index: -1;
    }
    img.effect1 {
      right: 0;
    }
    img.effect2 {
      left: 0;
      bottom: 0;
    }

    div.content {
      > h2 {
        text-align: center;
        margin-bottom: 10px;
      }
      > p {
        text-align: center;
      }

      div.SliderMentory {
        margin-top: 50px;

        div.slick-list {
          padding-bottom: 50px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  div#Home {
    section.featuredMentor {
      padding-bottom: 100px;
    }
  }
}
