article#billing {
  .addPaymentMethodModal {
    z-index: 999999999;
  }

  .textPrimary {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 600;
  }
  .textSecondary {
    font-size: 14px;
    letter-spacing: 0;
    opacity: 0.9;
  }

  div.transactionsHistory {
    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      font-family: "Source Sans Pro", sans-serif;
      margin-top: 20px;
      font-size: 14px;

      th {
        margin: 0;
        padding: 0;
        border: none;
      }

      tr.head {
        background: $detailsLight;
        th {
          padding: 10px 0;
          width: auto;
        }
      }

      tr:not(.head) {
        width: 100%;
        border-bottom: 1px solid $detailsLight;
        td {
          padding: 10px 2px;
          a {
            color: $blue;
            text-decoration: none;
            font-weight: 600;
          }
        }
      }
      tr:last-child {
        border-bottom: none;
      }
    }
  }

  div.mentor {
    div.container {
      div.balance {
        border-bottom: 1px solid $details;
        padding-bottom: 17px;
        margin-bottom: 17px;

        p.textPrimary {
          span {
            color: $blue;
            font-weight: 700;
            font-size: 18px;
          }
        }
      }
      form.simple.paymentMethod {
        border-bottom: 1px solid $details;
        padding-bottom: 35px;
        margin-bottom: 35px;

        > div {
          display: flex;
          margin-top: 20px;

          p {
            font-size: 16px;
            line-height: 21px;
            margin-left: 25px;

            span {
              font-size: 13px;
              color: rgba($black, 0.8);
            }
          }
        }

        ul {
          li:first-child {
            list-style-type: none;
            margin: 0;
            strong {
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              margin-top: 25px;
              svg {
                margin-right: 5px;
              }
            }
          }
          li {
            font-size: 13px;
            margin-left: 22px;
            color: $black;
            margin-bottom: 8px;
            line-height: 19px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  div.mentee {
    div#subscription {
      margin-bottom: 25px;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          span.status {
            display: inline-block;
            background: #3ecf8e;
            border: 1px solid #ffffff;
            box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 10px;
          }
          span.name {
            span {
              opacity: 0.8;
            }
          }
        }
        button {
          max-width: 200px;
          width: 100%;
          white-space: nowrap;
        }
      }
    }
    div.ManageCards {
      margin-top: 20px;
      margin-bottom: 20px;

      button {
        margin-top: 8px;
        margin-bottom: 25px;
      }
    }
  }
}

@media (max-width: 700px) {
  article#billing {
    div.mentee {
      div#subscription {
        > div {
          display: block;
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
