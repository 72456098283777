@import "../../../sass/colors";

div#Listing {
  section.main {
    margin-top: 100px;

    div.content {
      align-content: flex-start;
      justify-content: space-between;

      div.profileCard {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 35px;

        width: 320px;
        min-width: 320px;

        background: #fff;
        box-shadow:
          0 0 1px 0 rgba(10, 31, 68, 0.1),
          0 18px 18px 0 rgba(10, 31, 68, 0.12);
        border-radius: 4px;
        height: 100%;
        text-align: center;
        margin-left: 25px;

        > p.price {
          color: #fff;
          font-weight: 600;
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 24px;
          width: 100%;
          display: block;
          background-image: $primaryDegrade;
          border-radius: 4px 4px 0 0;
          small {
            color: #fff;
          }
          span {
            color: #fff;
          }
        }

        span.photo {
          width: 100px;
          height: 100px;
          display: block;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
          margin: 50px auto 15px;

          &:after {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            background: #edeaea;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            border: 2px solid #fff;
          }
        }

        p.name {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 45px;
        }
        button {
          margin-bottom: 35px;
        }
      }
      div.profileCardMobile {
        display: none;
      }

      div.about {
        max-width: 670px;
        margin-right: 25px;

        a.backToResults {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          text-decoration: none;
          font-size: 16px;

          svg {
            margin-right: 5px;
          }
        }

        h2 {
          margin-bottom: 15px;
        }

        p.titleAbout {
          font-weight: 600;
          margin-bottom: 5px;
          margin-top: 30px;
        }

        ul {
          margin-top: 15px;
          margin-bottom: 10px;
          list-style-type: none;
          li {
            display: flex;
            align-items: center;

            margin-top: 12px;
            font-size: 14px;

            > svg {
              margin-right: 8px;
            }
          }
        }

        article#mentorDescription {
          margin-top: 40px;

          //Dynamic content
          p {
            margin: 1em 0;
          }

          //Iframe
          .video-container {
            display: block;
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;
          }
          .video-container iframe,
          .video-container object,
          .video-container embed {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        article#services {
          margin-bottom: 100px;
          border: 1px solid #f6f5fa;
          border-radius: 4px;

          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f6f5fa;
            padding: 15px 20px;

            &:last-child {
              border-bottom: none;
            }

            p {
              margin-right: 40px;

              span {
                font-size: 18px;
                letter-spacing: 0;
                display: block;

                &:first-child {
                  font-weight: 600;
                }
                &:last-child {
                  margin-top: 2px;
                  font-size: 16px;
                  opacity: 0.9;
                  line-height: 22px;
                }
              }
            }
            button {
              white-space: nowrap;
              font-size: 14px;
              padding: 12px 18px;
            }
          }
        }

        div.actions {
          margin-top: 40px;
          margin-bottom: 60px;
          a {
            text-decoration: none;
          }

          ul {
            li {
              font-size: 16px;
              font-weight: 600;
              transition: color 0.35s;
              cursor: pointer;
              span {
                transition: 0.35s;
              }

              &:hover {
                color: $blue;
                span {
                  color: $blue;
                }
              }
            }
          }
        }

        div.qualifications {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          max-width: 500px;
          margin-bottom: 100px;

          div.Timeline {
            &:first-child {
              margin-right: 15px;
            }
            &:last-child {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }

  section.relatedMentors {
    background: $bkg;
    padding-top: 80px;
    padding-bottom: 90px;
    margin-top: 100px;

    .slick-list {
      padding-bottom: 35px;
    }

    h2,
    p.subtitle {
      text-align: center;
    }
    p.subtitle {
      margin-top: 10px;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 850px) {
  div#Listing {
    section.main {
      margin-top: 50px;

      div.content {
        display: block;

        div.profileCard {
          display: none;
        }
        div.profileCardMobile {
          position: fixed;
          bottom: 0;
          left: 0;
          background: #fff;
          width: 100%;
          display: block;
          padding: 15px;
          background: #ffffff;
          box-shadow: 0 -8px 14px 0 rgba(75, 68, 128, 0.09);
          z-index: 999999;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            justify-content: center;

            span.photo {
              width: 40px;
              height: 40px;
              display: block;
              border-radius: 50%;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              position: relative;
              margin-right: 10px;

              &:after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background: #edeaea;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 50%;
                border: 2px solid #fff;
              }
            }
            > div {
              p {
                margin: 0;
                padding: 0;
              }
              p.name {
                font-size: 14px;
                line-height: 15px;
              }
              p.price {
                font-size: 15px;
                font-weight: 600;

                span {
                  font-size: 12px;
                }
              }
            }
          }

          button {
            max-width: 150px;
            font-size: 14px;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
        }

        div.about {
          article#services {
            margin-bottom: 50px;
            > div {
              display: block;

              p {
                margin: 0;
              }
              button {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  div#Listing {
    section.main {
      margin-top: 50px;

      div.content {
        div.about {
          div.qualifications {
            flex-direction: column;

            div.Timeline {
              margin: 0 !important;
              &:first-child {
                margin-bottom: 50px !important;
              }
            }
          }
        }
      }
    }
  }
}

div.paymentSelection {
  a {
    color: $blue;
    text-decoration: none;
    font-weight: 600;
  }
  div.RadioButtom {
    div.container.v {
      > p {
        margin-bottom: 45px !important;

        div.Card {
          margin-left: 20px;
        }
      }
    }
  }
}

div.ModalBookinDetails {
  z-index: 999999 !important;

  div.serviceRequested {
    p {
      &:first-child {
        font-weight: 600;
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  div.selectBox {
    margin-bottom: 50px;

    span.openexchangerates {
      display: block;
    }
  }

  div.SelectTime {
    margin-top: 50px;
    margin-bottom: 70px;

    > div {
      > div {
        align-items: center;
        justify-content: space-between;
        width: 215px;

        span.photo {
          width: 50px;
          height: 50px;
          display: block;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
          margin-right: 12px;
        }
        p {
          margin-right: 10px;

          span {
            display: block;
            margin: 0;
            margin-bottom: -5px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          span.time {
            font-size: 16px;
          }
          span.date {
            font-size: 14px;
            white-space: nowrap;
          }
          span.location {
            font-size: 14px;
            opacity: 0.8;
            line-height: 17px;
          }
        }
      }
      div.rangeslider {
        width: 100%;
        max-width: 350px;
        margin-left: 25px;
      }
    }

    div.mentorTime {
      div.rangeslider,
      div.rangeslider__fill {
        background: $detailsLight;
      }
      div.rangeslider__handle {
        width: 10px;
        height: 10px;
        border: none;
        cursor: auto;
      }
    }
  }

  div.actions {
    margin-top: 50px;
    align-items: center;

    p {
      font-size: 14px;
      margin-left: 30px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

@media (max-width: 700px) {
  div.ModalBookinDetails {
    div.SelectTime {
      margin-top: 50px;
      margin-bottom: 70px;

      > p {
        margin-bottom: 10px;
      }

      > div.menteeTime {
        margin-bottom: 30px;
      }
      > div {
        display: block;

        > div {
          width: 215px;
          margin-bottom: 30px;

          svg {
            display: none;
          }
        }
        div.rangeslider {
          margin: 0;
        }
      }

      div.mentorTime {
        div.rangeslider__handle {
          width: 20px;
          height: 20px;
          border: none;
          cursor: auto;
        }
      }
    }

    div.actions {
      margin-top: 80px;
      display: block;
      text-align: center;

      button {
        margin: 0 auto;
        max-width: 100%;
        width: 100%;
      }

      p {
        font-size: 14px;
        margin: 0;
        margin-top: 30px;
      }
    }
  }
}
