@import "../../../sass/_colors";

div.StarRatings {
  display: flex;
  align-items: center;

  > span {
    display: block;
    margin-left: 7px;
    color: $blue;
    font-size: 16px;
    font-weight: 700;
  }
}
