div#Home {
  section.ctaMakeMoney {
    margin-top: 250px;

    div.content {
      > div.flex {
        justify-content: space-between;
        align-items: center;

        > div {
          max-width: 490px;
          margin-right: 25px;

          h2 {
            margin-bottom: 20px;
          }
          p {
            margin-bottom: 30px;
          }
        }
        > img {
          margin-left: 25px;
          max-width: 550px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  div#Home {
    section.ctaMakeMoney {
      margin-top: 150px;

      div.content {
        > div.flex {
          flex-direction: column;
          text-align: center;

          > div {
            margin: 0 auto;
            order: 2;
          }
          > img {
            order: 1;
            max-width: 350px;
            height: auto !important;
            margin: 0 auto 50px;
          }
        }
      }
    }
  }
}
