@import "../../../sass/_colors";

div#CreateListing,
div#EditListing {
  background: $bkg;

  section#createAndEditListingContainer {
    margin-top: 100px;
    margin-bottom: 100px;

    div.content {
      > h1 {
        text-align: center;
      }
      > p.subtitle {
        text-align: center;
      }

      form {
        > div.createListingBox {
          margin-top: 65px;

          div.container {
            > div.EditorMentory {
              display: block;

              div.rdw-link-modal {
                height: auto;

                [type="checkbox"]:checked + span {
                  &:before {
                    width: 9px !important;
                    height: 19px !important;
                  }
                }
                label.rdw-link-modal-target-option {
                  span {
                    &:before {
                      width: 15px;
                      height: 15px;
                    }
                    font-size: 15px;
                  }
                }
              }
            }

            > div.uploadPhoto {
              margin-bottom: 30px;

              span.error {
                display: inline-block;
                color: #ff1919;
                font-size: 14px;
                margin-top: 5px;
              }

              > div {
                label {
                  display: block;
                  font-weight: 600;
                }
                > p {
                  margin-top: 5px;
                  font-size: 14px;
                }
                span.photo {
                  display: block;
                  width: 125px;
                  height: 125px;
                  border-radius: 50%;

                  background-color: #d8d8d8;
                  background-size: cover;
                  background-position: center;

                  position: relative;
                  cursor: pointer;
                  margin-top: 12px;

                  img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                  }
                  input {
                    display: none;
                  }
                }
              }
            }

            > div {
              display: flex;
              justify-content: space-between;

              div.Input,
              div.InputTag,
              div.Dropdown {
                max-width: 48%;
                width: 100%;
              }

              div.paid {
                display: flex;
                justify-content: space-between;
                max-width: 48%;
                width: 100%;

                > div.Input {
                }
              }
            }
          }
        }

        div.servicesOffered {
          margin-top: 15px;

          > div {
            div.switch {
              span.lever {
                min-width: 36px;
              }
              span.label {
                display: none;
              }
            }

            span.error {
              display: inline-block;
              color: #ff1919;
              font-size: 14px;
              margin-top: 10px;
            }

            > p {
              display: block;
              padding-bottom: 12px;
              font-weight: 600;
            }
            article {
              > div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding: 12px 15px;
                border: 1.2px solid #f6f5fa;

                max-width: 990px;
                width: 100%;

                p.service {
                  margin-left: 30px;
                  margin-right: 100px;
                  max-width: 490px;
                  width: 100%;

                  span {
                    display: block;

                    &:first-child {
                      font-weight: 600;
                      margin-bottom: -3px;
                    }
                  }
                }

                div.boxes.disabled {
                  opacity: 0.5;
                }
                div.boxes {
                  display: flex;

                  > div.active {
                    background: $blue;
                    border-color: transparent;
                    span {
                      color: #fff;
                    }
                    small {
                      color: #fff;
                    }
                  }
                  > div {
                    border: 1px solid $details;
                    text-align: center;
                    padding: 10px 30px;
                    margin-left: 15px;
                    cursor: pointer;
                    border-radius: 2px;
                    max-width: 125px;
                    width: 100%;

                    &:first-child {
                      margin-left: 0;
                    }

                    span {
                      line-height: 22px;
                      white-space: nowrap;
                    }
                    small {
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
        }

        > div.actions {
          align-items: center;
          margin-top: 42px;

          button {
            max-width: 220px;
            width: 100%;
          }
          p {
            margin-left: 30px;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            a {
              text-decoration: none;
              transition: 0.35s;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  div#CreateListing,
  div#EditListing {
    section#createAndEditListingContainer {
      margin-top: 100px;
      margin-bottom: 100px;

      div.content {
        > h1 {
          text-align: center;
        }
        > p.subtitle {
          text-align: center;
        }
        form {
          > div.createListingBox {
            div.container {
              > div {
                display: block;

                div.Input,
                div.InputTag,
                div.Dropdown {
                  max-width: 100%;
                }

                div.paid {
                  display: block;
                  max-width: 100%;
                }
              }
            }
          }
          div.servicesOffered {
            > div {
              div.switch {
                margin-bottom: 20px;

                label {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  span.label {
                    display: block;
                  }
                }
              }

              > p {
                display: block;
                padding-bottom: 12px;
                font-weight: 600;
              }
              article {
                > div {
                  display: block;

                  p.service {
                    margin: 0 auto;
                    text-align: center;
                    span {
                      &:first-child {
                        display: none;
                      }
                    }
                  }

                  div.boxes {
                    display: block;

                    > div {
                      margin: 20px auto !important;

                      &:first-child {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 468px) {
  div#CreateListing,
  div#EditListing {
    background: $bkg;

    section#createAndEditListingContainer {
      margin-top: 50px;
      margin-bottom: 50px;

      div.content {
        form {
          > div.actions {
            flex-direction: column;
            justify-content: center;

            button {
              max-width: 100%;
              width: 100%;
            }
            p {
              margin: 20px auto;
            }
          }
        }
      }
    }
  }
}
