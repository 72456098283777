@import "../../../sass/_colors.scss";

div.CardPublicProfile {
  position: relative;
  max-width: 360px;
  width: 100%;
  background: #fff;
  box-shadow: 0 6px 14px 0 rgba(75, 68, 128, 0.13);
  border-radius: 6px;
  padding: 28px;
  transition: box-shadow 0.35s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 20px 30px 0 rgba(75, 68, 128, 0.13);
  }

  p.price {
    color: $blue;
    font-weight: 700;
    margin-bottom: 12px;
    small {
      color: $blue;
    }
  }
  h4 {
    margin-bottom: 15px;
    max-width: 220px;
  }
  p.name {
    font-size: 14px;
    font-weight: 600;
  }
  > ul {
    margin-top: 20px;
    outline: none;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        margin-right: 15px;
      }
    }
  }
}
