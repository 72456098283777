@import "../../../../sass/_colors.scss";

a.MyListingCard {
  text-decoration: none;
  position: relative;
  max-width: 360px;
  width: 100%;
  background: #fff;
  box-shadow: 0 6px 14px 0 rgba(75, 68, 128, 0.13);
  border-radius: 6px;
  padding: 28px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 14px 20px 0 rgba(75, 68, 128, 0.2);
  }

  > div {
    div.settings {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      cursor: pointer;

      > svg {
        color: $black;
      }
    }

    /* Status */
    span.status.published {
      background: #3ecf8e;
      opacity: 1;
    }
    span.status.pending {
      background: #ff9721;
      opacity: 1;
    }
    span.status.paused {
      background: #6b7c93;
      opacity: 1;
    }

    span.status {
      display: inline-block;
      padding: 3px 14px;
      outline: none;
      border-radius: 11px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 15px;
      opacity: 0;
      background: transparent;
      text-transform: capitalize;
    }

    p.price {
      color: $blue;
      font-weight: 700;
      margin-bottom: 12px;
      small {
        color: $blue;
      }
    }
    h4 {
      margin-bottom: 5px;
      max-width: 220px;
    }
    p.name {
      font-size: 14px;
      font-weight: 600;
    }
    > ul {
      margin-top: 20px;
      outline: none;
      list-style-type: none;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          margin-right: 15px;
        }
      }
    }
  }
}
