div.Response {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.5);
  display: none;
  animation-duration: 0.25s;
  z-index: 9999999999999999;

  div.containerResponse {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
    text-align: center;

    div.box {
      background: #fff;
      padding: 35px;
      box-shadow:
        0 0 1px 0 rgba(10, 31, 68, 0.1),
        0 26px 26px 0 rgba(10, 31, 68, 0.12);
      border-radius: 6px;
      opacity: 0;
      animation-duration: 0.5s;
      animation-delay: 0.1s;
      position: relative;
      max-width: 470px;
      width: 100%;

      span.close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }

      figure.error {
        color: #ff0000;
      }
      figure.success {
        color: #0bb07b;
      }
      figure.warning {
        color: #ffae42;
      }

      h3 {
      }
      p {
        max-width: 320px;
        margin: 10px auto 20px;
        display: block;
      }
      button {
      }
    }
  }
}
